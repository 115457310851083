import { Component, inject, input, OnInit, output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import moment from 'moment';

import { NameValue } from '@ui/shared/models';
import {
  ActionState,
  AppInputDirective,
  ButtonComponent,
  DateComponent,
  DropdownSelectComponent,
  FlatSelectComponent,
  FocusFormErrorDirective,
  FormFieldComponent,
  FormFieldLabelComponent,
  HeadingComponent,
  HintComponent,
  LoadingSpinnerComponent
} from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { BaseSchufaStep } from '../../base-schufa-step';
import { SchufaCertificatesComponent } from '../schufa-certificates/schufa-certificates.component';
import { ConfirmationInfoComponent } from '../confirmation-info/confirmation-info.component';
import { SchufaAddressComponent } from '../schufa-address/schufa-address.component';
import { SchufaBenefitsCardComponent } from '../schufa-benefits-card/schufa-benefits-card.component';
import { SchufaOrderResponseData } from '../../../../core/queries';

const BOOLEAN_OPTIONS = [
  { name: 'general.no_l', value: false },
  { name: 'general.yes_l', value: true }
];

@UntilDestroy()
@Component({
  selector: 'app-schufa-personal-form',
  templateUrl: './schufa-personal-form.component.html',
  styleUrls: ['./schufa-personal-form.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,

    SchufaBenefitsCardComponent,
    SchufaAddressComponent,
    ConfirmationInfoComponent,
    SchufaCertificatesComponent,
    TranslateModule,
    FocusFormErrorDirective,
    HeadingComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    DropdownSelectComponent,
    AppInputDirective,
    DateComponent,
    FlatSelectComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
    HintComponent
  ]
})
export class SchufaPersonalFormComponent
  extends BaseSchufaStep
  implements OnInit
{
  readonly personalDataActionState = input<ActionState>(undefined);
  readonly personalDataResonse = input<SchufaOrderResponseData>(undefined);
  readonly genderTypes = input<NameValue<string>[]>(undefined);
  readonly retry = output();
  public options = BOOLEAN_OPTIONS;
  public maxDateStruct: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  public startDateStruct: NgbDateStruct = {
    year: 1990,
    month: 1,
    day: 1
  };
  public minDateStruct: NgbDateStruct = {
    year: 1900,
    month: 1,
    day: 1
  };
  private fb = inject(FormBuilder);
  private maxPreAddressCount = 3;

  get preAddress() {
    const personalData = this.form().get('personalData') as FormGroup;
    return personalData.get('preAddress') as FormArray;
  }

  get preAddressControls() {
    return this.preAddress.controls as FormGroup[];
  }

  get address() {
    const personalData = this.form().get('personalData') as FormGroup;
    return personalData.get('address') as FormGroup;
  }

  get lastYearMoved() {
    return this.form().get('lastYearMoved') as FormControl;
  }

  get shouldRetry() {
    const personalDataResonse = this.personalDataResonse();
    return (
      personalDataResonse &&
      personalDataResonse.status &&
      personalDataResonse.status.next &&
      personalDataResonse.status.next.split(' ')[0] === 'retry'
    );
  }

  public preAddressCountLabel(total: number, index: number): string {
    return total > 1 ? ` (${index + 1}/${total})` : '';
  }

  ngOnInit() {
    this.lastYearMoved.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(val => {
        if (val) {
          this.addOption();
        } else {
          // remove all addresses
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          this.preAddress.value.forEach(() => this.removeOption(0));
        }
      });
  }

  public addOption() {
    if (this.preAddress.length < this.maxPreAddressCount) {
      this.preAddress.push(
        this.fb.group({
          country: ['DEU', Validators.required],
          zipCode: ['', Validators.required],
          city: ['', Validators.required],
          street: ['', Validators.required],
          houseNumber: ['']
        })
      );
    }
  }

  public onRetry() {
    this.retry.emit();
  }

  public removeOption(index: number) {
    this.preAddress.removeAt(index);
  }
}
