import { Component, input, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AddressFormComponent,
  AttachmentsComponent,
  BaseStep,
  defaultDocumentsConfig,
  FormFieldComponent,
  FormFieldLabelComponent,
  imagesTypes,
  PhoneInputComponent
} from '@ui/legacy-lib';

import { AddressFormConfig } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-profile-dk-one',
  templateUrl: './profile-dk-one.component.html',
  styleUrls: ['./profile-dk-one.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    AttachmentsComponent,
    AddressFormComponent,
    PhoneInputComponent
  ]
})
export class ProfileDkOneComponent extends BaseStep implements OnInit {
  public acceptedPhotoTypes = imagesTypes.join(',');
  public acceptedPhotoSize = defaultDocumentsConfig.acceptedDocumentSize;
  readonly preventPatchingCountryOnInit = input<boolean>(undefined);

  public addressFormConfig: AddressFormConfig = {
    showCountry: true,
    prefillFirstCountry: true,
    appendCountryToSearch: true
  };

  public get addressForm() {
    return this.form().get('address') as FormGroup;
  }

  public ngOnInit() {
    super.ngOnInit();
    this.addressFormConfig = {
      ...this.addressFormConfig,
      preventPatchingCountryOnInit: this.preventPatchingCountryOnInit()
    };
  }
}
