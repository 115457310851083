import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from '@apollo/client/core';
import { v4 as uuid } from 'uuid';

import {
  AuthTokenService,
  getCookiesPreference,
  getRedirectUrl,
  getResponseValidator,
  Go,
  RemoveTranslations,
  REQUEST_PASSWORD_RESET,
  RequestPasswordReset,
  RequestPasswordResetFail,
  RequestPasswordResetSuccess,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_SUCCESS_MESSAGE,
  ResetPassword,
  ResetPasswordFail,
  ResetPasswordSuccess,
  SessionStorageService,
  UPDATE_APPLICATION_SUCCESS,
  UpdateApplicationSuccess,
  UpdateCookieTracking,
  USER_LOGIN,
  USER_LOGIN_FAIL,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_LOGOUT_SUCCESS,
  USER_REDIRECT,
  USER_UPDATE_LAST_LOGIN,
  UserConversionService,
  UserLogin,
  UserLoginFail,
  UserLoginSuccess,
  UserLogout,
  UserLogoutFail,
  UserLogoutSuccess,
  UserRedirect,
  UserUpdateLastLoginSuccess,
  WINDOW_REF
} from '@ui/legacy-lib';

import { of } from 'rxjs';
import { FormStorageService } from '../../auth/services';
import {
  logoutMutation,
  LogoutMutationResult,
  requestPasswordResetMutation,
  resetPasswordMutation,
  ResetPasswordResponse,
  ssoQuery,
  userUpdateLastLogin
} from '../../core/queries';
import { MainPageNavigation, storageKeys } from '../../config';
import { SSOQueryResult } from '../../models';
import { getUserData } from '../user';
import { ENVIRONMENT_CONFIG } from '../../core';
import { USER_LOGOUT_SILENTLY, UserLogoutSilently } from './auth.actions';

@Injectable()
export class AuthEffects {
  private actions$ = inject(Actions);
  private environment = inject(ENVIRONMENT_CONFIG);

  userLoginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_LOGIN_FAIL),
      map(() => new UserRedirect())
    )
  );
  userRedirect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserRedirect>(USER_REDIRECT),
      map(
        ({ pathAfterAuth }) =>
          new Go({
            path: [MainPageNavigation.LOGIN],
            query: { pathAfterAuth }
          })
      )
    )
  );
  private authTokenService = inject(AuthTokenService);
  resetPasswordSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetPasswordSuccess>(RESET_PASSWORD_SUCCESS),
      map(() => {
        this.authTokenService.removeToken();
        return new Go({
          path: ['/login'],
          query: { message: RESET_PASSWORD_SUCCESS_MESSAGE }
        });
      })
    )
  );
  private apollo = inject(Apollo);
  userUpdateLastLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_UPDATE_LAST_LOGIN),
      switchMap(() =>
        this.apollo
          .mutate({ mutation: userUpdateLastLogin })
          .pipe(map(() => new UserUpdateLastLoginSuccess()))
      )
    )
  );
  requestPasswordReset$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RequestPasswordReset>(REQUEST_PASSWORD_RESET),
      switchMap(action =>
        this.apollo
          .mutate({
            mutation: requestPasswordResetMutation,
            variables: { email: action.email }
          })
          .pipe(
            map(() => new RequestPasswordResetSuccess()),
            catchError(err => [
              new RequestPasswordResetFail(new Error(err.message))
            ])
          )
      )
    )
  );
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetPassword>(RESET_PASSWORD),
      map(action => action.resetPasswordPayload),
      switchMap(payload =>
        this.apollo
          .mutate<ResetPasswordResponse>({
            mutation: resetPasswordMutation,
            variables: {
              password: payload.password,
              confirmedPassword: payload.confirmedPassword,
              token: payload.token
            }
          })
          .pipe(
            map((res: ApolloQueryResult<ResetPasswordResponse>) => {
              const successCodes = [200, 201, 202];
              if (!successCodes.includes(res.data.resetPassword.status)) {
                return new ResetPasswordFail(
                  new Error('ERR_SETTING_NEW_PASSWORD')
                );
              }

              return new ResetPasswordSuccess();
            }),
            catchError(error => [new ResetPasswordFail(error)])
          )
      )
    )
  );
  private userConversionService = inject(UserConversionService);
  private sessionStorage = inject(SessionStorageService);
  private formStorageService = inject(FormStorageService);
  userLoginSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLoginSuccess>(USER_LOGIN_SUCCESS),
        tap(({ token }) => {
          this.formStorageService.clear();
          this.sessionStorage.removeItem('propertyData');
          if (token) {
            this.authTokenService.setToken(token);
          }
        })
      ),
    { dispatch: false }
  );
  private store = inject(Store);

  // We need updateLastLogin for an anonymous account check, Backend handle all important stuff.
  updateLastLoginSucces$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateApplicationSuccess>(UPDATE_APPLICATION_SUCCESS),
      switchMap(() =>
        this.store.select(getUserData).pipe(
          filter(user => !!user?.id),
          withLatestFrom(this.store.select(getCookiesPreference))
        )
      ),
      map(([user, cookiePreference]) => {
        const payload = this.userConversionService.convertPsUser(user);
        return new UpdateCookieTracking(cookiePreference, payload);
      })
    )
  );
  private windowRef = inject(WINDOW_REF);
  userLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(USER_LOGIN),
        switchMap((action: UserLogin) => {
          const { email, redirectUri, loginMethod } = action.userCredentials;

          return this.apollo
            .query({
              query: ssoQuery,
              variables: { email, redirectUri, loginMethod }
            })
            .pipe(
              tap(getResponseValidator()),
              map((result: ApolloQueryResult<SSOQueryResult>) => {
                const { nonce, state, url } = result.data.sso;

                this.sessionStorage.setItem('nonce', nonce);
                this.sessionStorage.setItem('state', state);
                this.sessionStorage.setItem('sessionID', uuid());
                this.windowRef.location.href = url;
              }),
              catchError(err => of(new UserLoginFail(err.message)))
            );
        })
      ),
    { dispatch: false }
  );
  userLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UserLogout>(USER_LOGOUT),
      withLatestFrom(this.store.select(getUserData)),
      switchMap(([action, user]) => {
        const { internalPoolReturnUrl } = user || {
          internalPoolReturnUrl: ''
        };
        const redirectUri = action.redirectUrl
          ? action.redirectUrl
          : getRedirectUrl(
              this.windowRef.location.toString(),
              '/login?loggedOut=true',
              null
            );

        const session_state = this.authTokenService.getToken()?.session_state;

        return this.apollo
          .mutate({
            mutation: logoutMutation,
            variables: { redirectUri, session_state }
          })
          .pipe(
            tap(getResponseValidator()),
            mergeMap((result: ApolloQueryResult<LogoutMutationResult>) => {
              const { url } = result.data.userLogout;

              this.sessionStorage.removeItem(
                storageKeys.redirectedToNewHomeAfterLogin
              );
              this.sessionStorage.removeItem('nonce');
              this.sessionStorage.removeItem('state');
              this.authTokenService.removeToken();

              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return [
                new RemoveTranslations() as any,
                new UserLogoutSuccess(url, internalPoolReturnUrl)
              ];
            }),
            catchError(err => [new UserLogoutFail(err)])
          );
      })
    )
  );
  userLogoutSilently$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLogoutSilently>(USER_LOGOUT_SILENTLY),
        withLatestFrom(this.store.select(getUserData)),
        switchMap(() => {
          const session_state = this.authTokenService.getToken()?.session_state;

          return this.apollo
            .mutate({
              mutation: logoutMutation,
              variables: {
                redirectUri: this.environment.property_searcher_base_url,
                session_state
              }
            })
            .pipe(
              tap(getResponseValidator()),
              tap(() => {
                this.sessionStorage.removeItem(
                  storageKeys.redirectedToNewHomeAfterLogin
                );
                this.sessionStorage.removeItem('nonce');
                this.sessionStorage.removeItem('state');
                this.authTokenService.removeToken();
              }),
              catchError(err => [new UserLogoutFail(err)])
            );
        })
      ),
    { dispatch: false }
  );
  userLogoutSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserLogoutSuccess>(USER_LOGOUT_SUCCESS),
        map(({ redirectUrl, internalPoolReturnUrl }) => {
          if (internalPoolReturnUrl) {
            this.windowRef.open(internalPoolReturnUrl, '_self');
          } else {
            this.windowRef.location.href = redirectUrl;
          }
        })
      ),
    { dispatch: false }
  );
}
