import { Component, input, OnInit, output } from '@angular/core';

import {
  ActionState,
  AddressPipe,
  AppInputDirective,
  ButtonComponent,
  DateTimePipe,
  FocusFormErrorDirective,
  FormFieldComponent,
  FormFieldLabelComponent,
  FullNamePipe,
  HeadingComponent,
  LoadingSpinnerComponent
} from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseSchufaStep } from '../../base-schufa-step';
import { SchufaBenefitsCardComponent } from '../schufa-benefits-card/schufa-benefits-card.component';
import { SchufaCertificatesComponent } from '../schufa-certificates/schufa-certificates.component';
import { ConfirmationInfoComponent } from '../confirmation-info/confirmation-info.component';
import { SchufaOrderResponseData } from '../../../../core/queries';
import { SchufaPersonalInformationData } from '../../../../models';

@Component({
  selector: 'app-schufa-payment-order',
  templateUrl: './schufa-payment-order.component.html',
  styleUrls: ['./schufa-payment-order.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,

    ConfirmationInfoComponent,
    SchufaCertificatesComponent,
    SchufaBenefitsCardComponent,
    TranslateModule,
    AddressPipe,
    DateTimePipe,
    FullNamePipe,
    FocusFormErrorDirective,
    HeadingComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    ButtonComponent,
    LoadingSpinnerComponent
  ]
})
export class SchufaPaymentOrderComponent
  extends BaseSchufaStep
  implements OnInit
{
  readonly paymentActionState = input<ActionState>(undefined);
  readonly orderActionState = input<ActionState>(undefined);
  readonly paymentResponse = input<SchufaOrderResponseData>(undefined);
  readonly paymentErrorCount = input<number>(undefined);
  readonly personalData = input<SchufaPersonalInformationData>(undefined);

  readonly openSchufaPaymentMoreInformation = output();

  get hasError() {
    const paymentResponse = this.paymentResponse();
    return (
      paymentResponse &&
      paymentResponse.error &&
      paymentResponse.error.length > 0
    );
  }

  get retryAndNext() {
    const paymentResponse = this.paymentResponse();
    return (
      paymentResponse &&
      paymentResponse.error &&
      paymentResponse.error.find(
        error => error.code === 'ValidierungUniserv.0002'
      )
    ); // retry or next error
  }

  get address() {
    return this.personalData().address;
  }

  get birthdate() {
    return this.personalData().dateOfBirth;
  }

  public onOpenSchufaPaymentMoreInformation() {
    this.openSchufaPaymentMoreInformation.emit();
  }
}
