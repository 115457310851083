import { StorageKeys } from '@ui/legacy-lib';

interface TenantPoolStorageKeys extends StorageKeys {
  appVersion: string;
  appBuild: string;
  userRegistered: string;
  propertyIdToApply: string;
  propertyTokenToApply: string;
  personalInformation: string;
  rentedPropertiesIds: string;
  confirmIntentForApplication?: string;
  selectViewingAppointment?: string;
  stateAfterAuth?: string;
  /**
   * This key help us keep track if user has been redirected to profile edit page
   * after applying, when he's already logged in.
   * Knowing if he did so let us know, that redirection already happened, without waiting
   * for CreateApplication action to complete.
   */
  redirectedToProfileAfterApply?: string;
  redirectedToNewHomeAfterLogin?: string;
  selfDisclosureApplicationId?: string;
  selfDisclosureDeclareIntentId?: string;
  userActivenessSelection?: string;
  landlordThemeToken?: string;
  digitalContract?: string;
  adBlockerInfo?: string;
  alreadyExistingUserSearchProfile?: string;
  portalRegistration?: string;
  registrationToken?: string;
  registrationTokenId?: string;
  identityToken?: string;
  customQuestionApplicationId?: string;
  searchProfileData?: string;
  smartDepositApplicationForm: string;
}

export const storageKeys: TenantPoolStorageKeys = {
  appVersion: 'appVersion',
  appBuild: 'appBuild',
  tokenKey: 'token',
  localeKey: 'locale',
  cookiePreference: 'cookiePreference',
  emailVerifiedInCurrentSession: 'emailVerifiedInCurrentSession',
  userRegistered: 'userRegistered',
  propertyIdToApply: 'propertyIdToApply',
  personalInformation: 'personalInformation',
  propertyTokenToApply: 'propertyTokenToApply',
  rentedPropertiesIds: 'rentedPropertiesIds',
  redirectedToProfileAfterApply: 'redirectedToProfileAfterApply',
  redirectedToNewHomeAfterLogin: 'redirectedToNewHomeAfterLogin',
  confirmIntentForApplication: 'confirmIntentForApplication',
  selectViewingAppointment: 'selectViewingAppointment',
  stateAfterAuth: 'stateAfterAuth',
  selfDisclosureApplicationId: 'selfDisclosureApplicationId',
  selfDisclosureDeclareIntentId: 'selfDisclosureDeclareIntentId',
  customQuestionApplicationId: 'customQuestionApplicationId',
  userActivenessSelection: 'userActivenessSelection',
  landlordThemeToken: 'landlordThemeToken',
  digitalContract: 'digitalContract',
  adBlockerInfo: 'ad-blocker-info',
  propertyData: 'propertyData',
  portalRegistration: 'portalRegistration',
  registrationTokenId: 'registrationTokenId',
  redirectUri: 'redirectUri',
  identityToken: 'identityToken',
  smartDepositApplicationForm: 'smartDepositApplicationForm'
};
