import { Component, inject, input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  PropertyBean,
  PropertyMatchBean,
  SearchProfile
} from '@ui/shared/models';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

import { Store } from '@ngrx/store';

import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import {
  ActionState,
  AddressPipe,
  ButtonComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  Go,
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage,
  LoadingSpinnerComponent,
  ModalContentComponent,
  ModalV2Component,
  SlideToggleComponent
} from '@ui/legacy-lib';
import { AsyncPipe, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent } from 'angular-svg-icon';
import { MainPageNavigation } from '../../config';
import {
  getPropertyMatchesData,
  getPropertyMatchesDataActionState,
  getSearchProfiles,
  getSearchProfilesActionState,
  LoadPropertyMatchesBeans,
  LoadSearchProfiles
} from '../../+state';

@Component({
  selector: 'app-tenant-acceptance-modal',
  templateUrl: './tenant-acceptance-modal.component.html',
  styleUrls: ['./tenant-acceptance-modal.component.scss'],
  imports: [
    LottieComponent,
    SvgIconComponent,
    FormsModule,
    TranslateModule,
    AsyncPipe,
    CurrencyPipe,
    AddressPipe,
    ModalV2Component,
    ModalContentComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    SlideToggleComponent,
    LoadingSpinnerComponent,
    ButtonComponent
  ]
})
export class TenantAcceptanceModalComponent implements OnInit {
  readonly property = input<PropertyBean>(undefined);
  public lottieConfig: AnimationOptions;
  public activateSearchProfile = false;
  public closeOpenApplication = true;
  public searchProfiles$: Observable<SearchProfile[]>;
  public searchProfilesActionState$: Observable<ActionState>;
  public propertiesMatches$: Observable<PropertyMatchBean[]>;
  public propertiesMatchesActionState$: Observable<ActionState>;
  private ngbActiveModal = inject(NgbActiveModal);
  private store = inject(Store);

  public get isFlat() {
    return isPropertyTypeFlat(this.property()?.type);
  }

  public get isGarage() {
    return isPropertyTypeGarage(this.property()?.type);
  }

  public get isCommercial() {
    return isPropertyTypeCommercial(this.property()?.type);
  }

  public get haveFunWithPropertyTranslation() {
    return this.isFlat
      ? 'new-home.have_fun_with_new_flat_l'
      : this.isCommercial
        ? 'new-home.have_fun_with_new_commercial_l'
        : this.isGarage
          ? 'new-home.have_fun_with_new_garage_l'
          : '';
  }

  public get picture() {
    const property = this.property();
    return property?.titleImage || property?.data?.attachments?.[0];
  }

  ngOnInit() {
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.NEW_HOME]
      })
    );
    this.store.dispatch(new LoadSearchProfiles());
    this.store.dispatch(
      LoadPropertyMatchesBeans({
        data: { page: 0, size: 15, sort: 'id,asc' },
        loadMore: false
      })
    );
    this.propertiesMatches$ = this.store
      .select(getPropertyMatchesData)
      .pipe(shareReplay(1));
    this.propertiesMatchesActionState$ = this.store.select(
      getPropertyMatchesDataActionState
    );
    this.searchProfiles$ = this.store.select(getSearchProfiles);
    this.searchProfilesActionState$ = this.store.select(
      getSearchProfilesActionState
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.lottieConfig = {
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/data.json' // the path to the animation json
    };
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    this.ngbActiveModal.close({
      activateSearchProfile: this.activateSearchProfile,
      closeOpenApplication: this.closeOpenApplication
    });
  }
}
