import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, RouterOutlet } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Observable } from 'rxjs';
import { delay, filter, map, take } from 'rxjs/operators';

import {
  CookieBannerComponent,
  CountUnread,
  desktopBreakingPoints,
  fadeOnEnterLeaveAnimation,
  getEmailVerifiedInCurrentSession,
  HeaderV2Component,
  MessengerNotificationService,
  OpenCookieSettingsModal,
  PerformanceWarningBannerComponent,
  POLLING_TIME_PS,
  SessionStorageService,
  SetCookiesPreference,
  StopCountUnreadPolling,
  StopFindUnreadMessagesPolling,
  SurvicateService,
  ThemeService,
  UserConversionService,
  UserLogout
} from '@ui/legacy-lib';
import { CookiePreference, PropertySearcherUser } from '@ui/shared/models';

import { AsyncPipe } from '@angular/common';
import { BaseShellComponent } from '../base-shell.component';
import { NavigationItem } from '../../../models';
import { ENVIRONMENT_CONFIG } from '../../environment';
import {
  MainPageNavigation,
  navigationConfig,
  navigationUserDropdownConfig,
  storageKeys
} from '../../../config';
import { showAssistant } from '../../../config/hideTypes';
import {
  AssignRegistrationToken,
  FetchPreferredLanguage,
  getHasEmailVerified,
  getUserData,
  getVerificationEmailResent,
  LoadAppointments,
  LoadRentedFlatsApplications
} from '../../../+state';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';

@UntilDestroy()
@Component({
  selector: 'app-authenticated-shell',
  templateUrl: './authenticated-shell.component.html',
  styleUrls: ['./authenticated-shell.component.scss'],
  animations: [fadeOnEnterLeaveAnimation],

  imports: [
    MobileNavigationComponent,
    MainNavigationComponent,
    RouterOutlet,
    AsyncPipe,
    HeaderV2Component,
    PerformanceWarningBannerComponent,
    CookieBannerComponent
  ]
})
export class AuthenticatedShellComponent
  extends BaseShellComponent
  implements OnInit, OnDestroy
{
  public hasEmailVerified$: Observable<boolean>;
  public verificationEmailResent$: Observable<boolean>;
  public emailVerifiedInCurrentSession$: Observable<boolean>;
  public hideEmailBanner$: Observable<boolean>;
  public user$: Observable<PropertySearcherUser>;
  public unreadCount$: Observable<number>;
  public userData: PropertySearcherUser;
  public hasNewHome: boolean;
  public inInternalPool: boolean;
  public navigationUserDropdownItems: NavigationItem[];
  public navigationItemsDesktop: NavigationItem[];
  public navigationItemsMobile: NavigationItem[];
  private messengerNotificationService = inject(MessengerNotificationService);
  private sessionStorage = inject(SessionStorageService);
  private themeService = inject(ThemeService);
  private userConversionService = inject(UserConversionService);
  private environment = inject(ENVIRONMENT_CONFIG);
  #survicateService = inject(SurvicateService);

  public ngOnInit() {
    super.ngOnInit();
    /* eslint-enable */
    this.user$ = this.store.select(getUserData);

    this.user$
      .pipe(
        filter(user => !!user),
        untilDestroyed(this)
      )
      .subscribe(userData => {
        this.userData = userData;
        this.store.dispatch(new FetchPreferredLanguage());
        this.inInternalPool = userData.inInternalPool;
        this.store.dispatch(new CountUnread(POLLING_TIME_PS));
        void this.#survicateService.init(userData.email);
        if (
          userData?.customerBranding?.itpSettings?.whitelabel &&
          userData?.inInternalPool
        ) {
          this.themeService.createTheme(userData?.customerBranding);
        } else {
          this.themeService.setTheme(this.themeService.getDefaultTheme);
        }
      });

    // TODO rent deposit: fix routing
    this.hasNewHome = this.route.snapshot.data.hasNewHome;

    //this.store.dispatch(MovingChecklistGetByProperty());

    this.observer
      .observe(desktopBreakingPoints)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.navigationItemsDesktop = navigationConfig;

        const {
          schufaCard,
          shopCard,
          movingChecklist,
          movingAuction,
          milesCard,
          wechselPilotCard
        } = this.userData?.customerBranding?.itpSettings || {};
        if (
          !showAssistant(
            schufaCard,
            shopCard,
            movingChecklist,
            movingAuction,
            milesCard,
            wechselPilotCard
          )
        ) {
          this.navigationItemsDesktop = this.navigationItemsDesktop.filter(
            item => item.name !== MainPageNavigation.SERVICE_CENTER
          );
        }

        this.navigationItemsMobile = this.navigationItemsDesktop;
      });

    this.store.dispatch(LoadRentedFlatsApplications());

    this.navigationUserDropdownItems = navigationUserDropdownConfig;

    this.emailVerifiedInCurrentSession$ = this.store.select(
      getEmailVerifiedInCurrentSession
    );
    this.hasEmailVerified$ = this.store.select(getHasEmailVerified);
    this.verificationEmailResent$ = this.store.select(
      getVerificationEmailResent
    );
    this.hideEmailBanner$ = this.store
      .select(getEmailVerifiedInCurrentSession)
      .pipe(delay(5000), take(1));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.route),
        map(route => route.firstChild.snapshot)
      )
      .subscribe(snapshot => {
        this.updateVisibilityMessenger(snapshot.routeConfig.path);
      });

    this.messengerNotificationService.init();
    this.unreadCount$ = this.messengerNotificationService.unreadCount$;
    this.store.dispatch(new LoadAppointments());
    this.loginActionsHandling();
  }

  ngOnDestroy() {
    this.messengerNotificationService.complete();
  }

  public onLogout(): void {
    const redirectUri = this.environment.property_searcher_home_url;
    this.store.dispatch(new StopCountUnreadPolling());
    this.store.dispatch(new StopFindUnreadMessagesPolling());
    this.themeService.setTheme(this.themeService.getDefaultTheme);
    this.store.dispatch(new UserLogout(redirectUri));
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(
      new SetCookiesPreference(
        payload,
        this.userConversionService.convertPsUser(this.userData)
      )
    );
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(
      new OpenCookieSettingsModal(
        payload,
        true,
        this.userConversionService.convertPsUser(this.userData)
      )
    );
  }

  protected updateVisibilityMessenger(url: string) {
    this.displayEmailBanner = url !== 'messenger';
  }

  private loginActionsHandling() {
    // assign registrationTokenId to PS when logs in
    // through whitelabel link to be created as contact
    const registrationTokenId = this.sessionStorage.getItem<string>(
      storageKeys.registrationTokenId
    );
    if (registrationTokenId) {
      this.store.dispatch(new AssignRegistrationToken(registrationTokenId));
      this.sessionStorage.removeItem('registrationTokenId');
    }
  }
}
