import { Component, input, OnDestroy, OnInit, output } from '@angular/core';

import {
  BaseStep,
  LoadingSpinnerComponent,
  SCREEN_MEDIUM_MIN
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-contract-signing',
  templateUrl: './contract-signing.component.html',
  styleUrls: ['./contract-signing.component.scss'],

  imports: [TranslateModule, LoadingSpinnerComponent]
})
export class ContractSigningComponent
  extends BaseStep
  implements OnInit, OnDestroy
{
  readonly isSigned = input(false);
  readonly hasError = input(false);
  readonly showShopCard = input(false);

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onTrackRelocationShop = output();

  public get narrowScreen() {
    return window.innerWidth < SCREEN_MEDIUM_MIN;
  }

  public trackRelocationShop() {
    this.onTrackRelocationShop.emit();
  }
}
