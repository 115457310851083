import { Component, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentPosition } from '@ui/shared/models';
import { CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  ModalContentComponent,
  ModalV2Component
} from '@ui/legacy-lib';

@Component({
  selector: 'app-payment-positions-request-already-paid-modal',
  templateUrl: './payment-positions-request-already-paid-modal.component.html',
  styleUrls: ['./payment-positions-request-already-paid-modal.component.scss'],

  imports: [
    TranslateModule,
    CurrencyPipe,
    ModalV2Component,
    ModalContentComponent,
    ButtonComponent
  ]
})
export class PaymentPositionsRequestAlreadyPaidModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public selectedPositions = input<PaymentPosition[]>([]);
  public currency = input<string>(undefined);

  public onClose() {
    this.ngbActiveModal.close();
  }

  public onDismiss() {
    this.ngbActiveModal.dismiss();
  }
}
