<div class="customer-info__content">
  <div class="customer-info__logo me-2">
    <a href="{{ getLogoRedirectUrl() }}" target="_blank"
      ><img [src]="customerLogo()" alt="logo"
    /></a>
  </div>
  @if (showOfferFrom() || nameHeading()) {
    <div class="customer-info__offered col p0">
      <div class="uppercase-m semibold customer-info__header">
        {{ nameHeading() || 'property.offered_by_l' | translate }}
      </div>
      <div class="title-s customer-info__name">
        {{ name() || ('general.private_landlord_l' | translate) }}
      </div>
    </div>
  }
</div>
@if (showInCooperationWith() && logo()) {
  <img
    class="cooperation-logo"
    src="/assets/images/logos/Immomio_Logo_Kooperation.svg"
    alt="{{ 'property.in_cooperation_with_immomio_l' | translate }}"
  />
}
