import { Component, inject, Input, input, output } from '@angular/core';

import {
  ActionState,
  ButtonComponent,
  FlatSelectComponent,
  FocusFormErrorDirective,
  FormFieldComponent,
  HeadingComponent
} from '@ui/legacy-lib';

import { Store } from '@ngrx/store';
import { FormState, GenericFormGroup } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseSchufaStep } from '../../base-schufa-step';
import { SchufaBenefitsCardComponent } from '../schufa-benefits-card/schufa-benefits-card.component';
import { SchufaIdentificationComponent } from '../schufa-identification/schufa-identification.component';
import {
  SchufaDeliveryOption,
  SchufaIdentityData,
  SchufaPersonalInformationData,
  SchufaSalutation,
  SendSchufaDelivery
} from '../../../../models';
import { SchufaError } from '../../../../core/queries';

@Component({
  selector: 'app-schufa-delivery',
  templateUrl: './schufa-delivery.component.html',
  styleUrls: ['./schufa-delivery.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,

    SchufaIdentificationComponent,
    SchufaBenefitsCardComponent,
    TranslateModule,
    FocusFormErrorDirective,
    HeadingComponent,
    FlatSelectComponent,
    FormFieldComponent,
    ButtonComponent
  ]
})
export class SchufaDeliveryComponent extends BaseSchufaStep {
  readonly personalData = input<SchufaPersonalInformationData>(undefined);
  readonly deliveryActionState = input<ActionState>(undefined);
  readonly schufaIdentify = output<SendSchufaDelivery>();
  public deliveryOptions = [
    {
      name: 'schufa.delivery.download_l',
      value: SchufaDeliveryOption.DOWNLOAD
    },
    { name: 'schufa.delivery.post_l', value: SchufaDeliveryOption.POST }
  ];
  private store = inject(Store);

  private _deliveryResponseError = [] as SchufaError[];

  get deliveryResponseError() {
    return this._deliveryResponseError;
  }

  @Input() set deliveryResponseError(val: SchufaError[]) {
    if (val) {
      this._deliveryResponseError = val;
      this.form()?.markAllAsTouched();
    }
  }

  public get deliverySelection() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form().get('deliverySelection').value;
  }

  public get isMale() {
    return (
      this.personalData()?.salutation?.toUpperCase() === SchufaSalutation.HERR
    );
  }

  public get isDownload() {
    return this.deliverySelection === SchufaDeliveryOption.DOWNLOAD;
  }

  public get isPost() {
    return this.deliverySelection === SchufaDeliveryOption.POST;
  }

  public get downloadFormInvalid() {
    return this.isDownload && this.form().invalid;
  }

  public cancel() {
    this.onCancel.emit();
  }

  public onSubmit() {
    const form = this.form();
    form?.status === FormState.VALID ? this.submit() : form?.markAllAsTouched();
  }

  private submit() {
    if (this.isDownload) {
      const identityPayload = this.getIdentityPayload(this.form());
      this.schufaIdentify.emit(this.getValue(identityPayload));
    } else {
      const post = { postDelivery: true };
      this.form().patchValue(post);
      this.schufaIdentify.emit(post);
    }
  }

  private getIdentityPayload(
    form: GenericFormGroup<SendSchufaDelivery>
  ): SendSchufaDelivery {
    const { deliverySelection, identityData, ...rest } =
      form.value as SendSchufaDelivery & {
        deliverySelection: SchufaDeliveryOption;
      };
    const { withdrawalAccepted, ...restIdentityData } =
      identityData as SchufaIdentityData & { withdrawalAccepted: boolean };
    return {
      ...rest,
      identityData: { ...restIdentityData }
    };
  }

  private getValue(value: SendSchufaDelivery): SendSchufaDelivery {
    const { identityData, ...rest } = value;
    return {
      ...rest,
      identityData: {
        ...identityData,
        iBAN: identityData.iBAN.toUpperCase().replace(/\s/g, '')
      }
    };
  }
}
