import moment from 'moment';
import { Component, inject, input, model, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

import {
  CustomQuestion,
  PropertyMatchBean,
  QuestionContainer,
  RootQuestion
} from '@ui/shared/models';

import {
  areObjectsEqual,
  ButtonComponent,
  ButtonTypeEnum,
  CustomQuestionsModalContentComponent,
  customQuestionsPayloadHelper,
  Go,
  HierarchicalQuestionService,
  LandlordInfoComponent,
  LocalStorageService,
  ModalComponent,
  ModalContentComponent,
  ModalFooterComponent
} from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  CustomQuestionsState,
  getCustomQuestionActionState,
  getCustomQuestionErrorMessage,
  getCustomQuestionLoading,
  getSaveAnswersActionState,
  getUserData,
  HierarchicalQuestionResponse,
  LoadPropertyMatchBean,
  OpenCompleteGarageModal,
  SaveCustomQuestionsAnswers,
  SetHierarchicalQuestionsModalOpen
} from '../../+state';
import { NAVIGATION_LINK, storageKeys } from '../../config';

@Component({
  selector: 'app-custom-questions-modal',
  templateUrl: './custom-questions-modal.component.html',
  styleUrls: ['./custom-questions-modal.component.scss'],

  imports: [
    TranslateModule,
    AsyncPipe,
    CustomQuestionsModalContentComponent,
    ModalComponent,
    ModalContentComponent,
    LandlordInfoComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class CustomQuestionsModalComponent implements OnInit {
  public control = new FormControl();
  public hierarchicalRootQuestionControl = new FormControl();
  public isProcessing$: Observable<boolean>;
  public questionContainer = input<QuestionContainer>(undefined);
  public propertyMatchBean = input<PropertyMatchBean>(undefined);
  public customQuestions: CustomQuestion[];
  public rootQuestions: RootQuestion[];
  public hierarchicalQuestionErrorMessage$: Observable<string>;
  public hideAnsweredQuestions = input(false);
  public baselineQuestions = model<RootQuestion[]>([]);
  public openSecondModalForGarageProperties = input(false);
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  private ngbActiveModal = inject(NgbActiveModal);
  private store = inject<Store<CustomQuestionsState>>(Store);
  private hierarchicalQuestionService = inject(HierarchicalQuestionService);
  private localStorageService = inject(LocalStorageService);

  public ngOnInit(): void {
    this.store.dispatch(new SetHierarchicalQuestionsModalOpen(true));
    this.customQuestions = this.questionContainer().customQuestions.map(cq => {
      return cq.selectedRange
        ? {
            ...cq,
            selectedRange: moment(cq.selectedRange).format('YYYY-MM-DD')
          }
        : cq;
    });

    this.isProcessing$ = combineLatest([
      this.store.select(getCustomQuestionLoading),
      this.store.select(getSaveAnswersActionState)
    ]).pipe(map(([loading, state]) => loading || state.pending));

    this.hierarchicalQuestionErrorMessage$ = this.store.select(
      getCustomQuestionErrorMessage
    );

    this.rootQuestions = this.questionContainer().questions.map(
      q => q.rootQuestion
    );

    this.store
      .select(getUserData)
      .pipe(take(1))
      .subscribe(userData => {
        if (userData.profile.dateOfBirth) {
          // if user maintains dateOfBirth, then set it as the default value for the birthdate question
          // unless the user has already answered the birthDate question, then keep as it is
          this.rootQuestions = this.rootQuestions.map(q => ({
            ...q,
            questions: q.questions.map(item => {
              if (item.data?.idType === 'BIRTHDATE' && !item.userResponse) {
                return {
                  ...item,
                  userResponse: {
                    response: userData.profile.dateOfBirth
                  }
                };
              }
              return item;
            })
          }));
        }
      });

    this.control.patchValue(this.customQuestions);
    this.hierarchicalRootQuestionControl.patchValue(this.rootQuestions);
  }

  public onFormValidityChange(isValid: boolean): void {
    this.control.setErrors(isValid ? null : { missingFields: true });
  }

  public onHierarchicalFormValidityChange(isValid: boolean): void {
    this.hierarchicalRootQuestionControl.setErrors(
      isValid ? null : { missingFields: true }
    );
  }

  public dismiss(): void {
    this.store.dispatch(new SetHierarchicalQuestionsModalOpen(false));
    this.localStorageService.removeItem(
      storageKeys.customQuestionApplicationId
    );
    this.ngbActiveModal.dismiss();

    if (this.openSecondModalForGarageProperties())
      this.openCompleteProfileModal();
  }

  public save(): void {
    if (!this.control.valid) return;

    const payload = customQuestionsPayloadHelper(
      this.control.value,
      this.customQuestions
    );
    const hierarchicalRootQuestions =
      this.hierarchicalQuestionService.getHierarchicalRootQuestionPayload(
        this.hideAnsweredQuestions()
          ? this.mergeAnsweredQuestionsWithBaseline()
          : this.hierarchicalRootQuestionControl.value
      );
    this.store.dispatch(
      new HierarchicalQuestionResponse(hierarchicalRootQuestions)
    );
    this.store.dispatch(new SaveCustomQuestionsAnswers(payload));

    this.store
      .select(getSaveAnswersActionState)
      .pipe(
        filter(state => !!state.done),
        switchMap(() =>
          this.store.select(getCustomQuestionActionState).pipe(
            filter(state => !state.pending),
            withLatestFrom(this.hierarchicalQuestionErrorMessage$),
            take(1),
            filter(([_, errorMsg]) => !errorMsg)
          )
        )
      )
      .subscribe(() => {
        if (this.propertyMatchBean()) {
          this.store.dispatch(
            LoadPropertyMatchBean({ id: this.propertyMatchBean().id })
          );
          // When openSecondModalForGarageProperties is true, then we are already on PROPERTIES_APPLICATIONS
        } else if (!this.openSecondModalForGarageProperties()) {
          // if propertyMatch is not present that means we are opening the modal with customer Id
          // we navigate back to the applications page to clear the query params
          // so users won't be getting the modal again on reload
          this.store.dispatch(
            new Go({
              path: [NAVIGATION_LINK.PROPERTIES_APPLICATIONS]
            })
          );
        }
        this.store.dispatch(new SetHierarchicalQuestionsModalOpen(false));
        this.localStorageService.removeItem(
          storageKeys.customQuestionApplicationId
        );
        this.ngbActiveModal.close();

        if (this.openSecondModalForGarageProperties())
          this.openCompleteProfileModal();
      });
  }

  /**
   * This is getting called when a (un-)registered PS applies to a garage
   */
  public openCompleteProfileModal() {
    this.store.dispatch(OpenCompleteGarageModal());
  }

  private mergeAnsweredQuestionsWithBaseline() {
    const updatedQuestions = this.hierarchicalRootQuestionControl
      .value as RootQuestion[];

    updatedQuestions.forEach(question => {
      const baselineQuestion = this.baselineQuestions().find(
        base => base.id === question.id
      );

      this.baselineQuestions.update(questions =>
        questions.map(base => {
          if (
            base.id === question.id &&
            !areObjectsEqual(baselineQuestion, question)
          ) {
            return { ...question };
          } else {
            return base;
          }
        })
      );
    });
    return this.baselineQuestions();
  }
}
