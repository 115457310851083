import { defaultEnvironment } from './environment.defaults';

export const environment = {
  ...defaultEnvironment,

  production: false,
  development: false,
  mobile: false,
  deploymentEnv: 'integration',
  file_upload_path: 'https://gql-ps.itg.immomio.com/tenant/fileUpload',
  graphql_server_uri: 'https://gql-ps.itg.immomio.com/tenant/graphql',
  graphql_resident_server_uri: 'https://api-rs.itg.immomio.com/graphql',
  graphql_host: 'https://gql-ps.itg.immomio.com',
  backend_url: 'https://api-ps.itg.immomio.com/ps',
  filer_url: 'https://filer-ps.itg.immomio.com/ps',

  property_searcher_base_url: 'https://tenant.itg.immomio.com',
  property_searcher_home_url: 'https://tenant.itg.immomio.com/de/auth/login',
  landlord_info_url: 'https://www.immomio.de',
  landlord_app_url: 'https://app.itg.immomio.com',

  keycloakConfig: {
    ...defaultEnvironment.keycloakConfig,

    realm: 'immomio-itg',
    'auth-server-url': 'https://sso.itg.immomio.com/auth/'
  }
};
