import { gql } from 'apollo-angular';

import {
  AvailableLanguageCodesEnum,
  PropertySearcherUser,
  UserRegisteredPlatform
} from '@ui/shared/models';
import { landlordDistrictGroup } from '@ui/legacy-lib';

export interface UserQueryResponse {
  user: PropertySearcherUser;
}

export interface PreferredLanguageResponse {
  preferredLanguage: AvailableLanguageCodesEnum;
}

export type IsRegisteredAtImmomioResponse = {
  isRegisteredAtImmomio: UserRegisteredPlatform;
};

export const customerBrandingFields = `
  theme {
    name
    primaryColor
    secondaryColor
    primaryTextColor
    secondaryTextColor
    buttonTextColor
    backgroundColor
    cardBackgroundColor
    active
  }
  logoRedirectUrl
  logo {
    title
    url
    name
  }
  favicon {
    url
    title
    name
  }
  itpSettings {
    dmvCard
    shopCard
    movingChecklist
    movingAuction
    schufaCard
    milesCard
    wechselPilotCard
    whitelabel
    allowUserToLeaveItp
    showCreditScreeningDocumentUploadArea
    informalLanguage
  }
  countries
`;

export const userQuery = gql`
  query {
    user {
      id
      address {
        street
        houseNumber
        city
        country
        region
        zipCode
      }
      email
      profile
      emailVerified
      profileCompleteness
      searchUntil
      created
      internalPoolReturnUrl
      internalPoolCustomerName
      inInternalPool
      onlyShowConfiguredCityPartsToUser
      districtGroup {
        ${landlordDistrictGroup}
      }
      customerBranding {
       ${customerBrandingFields}
      }
    }
  }
`;

export interface FirstSocialLoginResponse {
  firstSocialLogin: boolean;
}

export const firstSocialLoginQuery = gql`
  query firstSocialLogin($email: String!) {
    firstSocialLogin(email: $email)
  }
`;

export interface SaveUserMutationResponse {
  saveUser: PropertySearcherUser;
}

export const saveUserMutation = gql`
  mutation saveUser($user: PropertySearcherUserInput!) {
    saveUser(user: $user) {
      id
      username
      status
      address {
        street
        houseNumber
        city
        country
        zipCode
        region
      }
      email
      profile
      emailVerified
      profileCompleteness
      searchUntil
      internalPoolReturnUrl
      internalPoolCustomerName
      inInternalPool
      customerBranding {
        theme {
          name
          primaryColor
          secondaryColor
          primaryTextColor
          secondaryTextColor
          buttonTextColor
          backgroundColor
          cardBackgroundColor
          active
        }
        logo {
          title
          type
          url
          identifier
          name
          extension
          encrypted
          index
        }
        itpSettings {
          dmvCard
          shopCard
          schufaCard
          milesCard
          wechselPilotCard
          whitelabel
        }
      }
    }
  }
`;

export const deleteUserMutation = gql`
  mutation deleteUser($input: DeleteUserInput) {
    deleteUser(input: $input) {
      redirectUri
    }
  }
`;

export const resendEmailVerificationMutation = gql`
  mutation resendEmailVerification($userId: ID!) {
    resendEmailVerification(userId: $userId)
  }
`;

export const verifyEmailMutation = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const setSearchingStatusMutation = gql`
  mutation setSearchingStatus($token: String!, $isSearching: Boolean!) {
    setSearchingStatus(token: $token, isSearching: $isSearching)
  }
`;

export const unlockAccountMutation = gql`
  mutation unlockAccount($token: String!) {
    unlockAccount(token: $token) {
      status
      statusText
    }
  }
`;

export const leaveInternalTenantPoolMutation = gql`
  mutation leaveInternalTenantPool {
    leaveInternalTenantPool
  }
`;

export const assignRegistrationTokenMutation = gql`
  mutation assignRegistrationToken($tokenId: ID!) {
    assignRegistrationToken(tokenId: $tokenId) {
      status
    }
  }
`;

export const changeEmailMutation = gql`
  mutation changeEmail($email: String!) {
    changeEmail(email: $email) {
      status
    }
  }
`;

export const confirmEmailMutation = gql`
  mutation confirmEmail($token: String!) {
    confirmEmail(token: $token)
  }
`;

export const tenantRegisterMutation = gql`
  mutation tenantRegister($tenantRegisterInput: TenantRegisterInput!) {
    tenantRegister(input: $tenantRegisterInput) {
      status
      statusText
    }
  }
`;

export const changePasswordMutation = gql`
  mutation changePassword($password: String!, $confirmedPassword: String!) {
    changePassword(password: $password, confirmedPassword: $confirmedPassword)
  }
`;

export const sagaTransferDataMutation = gql`
  mutation sagaTransferData($accept: Boolean!, $token: String!) {
    sagaDataTransfer(accept: $accept, token: $token) {
      statusText
    }
  }
`;

export const changePreferredLanguageMutation = gql`
  mutation changePreferredLanguage($languageCode: String) {
    changePreferredLanguage(languageCode: $languageCode) {
      status
      statusText
    }
  }
`;

export const fetchPreferredLanguageQuery = gql`
  query preferredLanguage {
    preferredLanguage
  }
`;

export const fetchIsRegisteredAtImmomioQuery = gql`
  query isRegisteredAtImmomio {
    isRegisteredAtImmomio {
      registeredAtImmomio
    }
  }
`;

export const registerResidentMutation = gql`
  mutation {
    registerResident {
      status
      statusText
    }
  }
`;
