import { Component, Input, output } from '@angular/core';
import {
  BaseStep,
  ButtonComponent,
  CheckboxComponent,
  DigitalContractSignerComponent,
  FormFieldComponent
} from '@ui/legacy-lib';
import {
  DigitalContract,
  INTERNATIONAL_OPTIONS,
  INTERNATIONAL_QES_OPTIONS,
  NameValue,
  SignatureType
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-contract-personal-information',
  templateUrl: './contract-personal-information.component.html',
  styleUrls: ['./contract-personal-information.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DigitalContractSignerComponent,
    FormFieldComponent,
    CheckboxComponent,
    ButtonComponent
  ]
})
export class ContractPersonalInformationComponent extends BaseStep {
  @Input() set contract(value: DigitalContract | undefined) {
    if (!value) return;

    this.isPhoneRequired = [SignatureType.AES_SMS, SignatureType.QES].includes(
      value.signatureType
    );
    this.internationalPhoneOptions =
      value.signatureType === SignatureType.QES
        ? INTERNATIONAL_QES_OPTIONS
        : INTERNATIONAL_OPTIONS;
  }

  public internationalPhoneOptions: NameValue[];
  public isPhoneRequired: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onSendMessage = output();

  public get signerForm() {
    return this.form().get('tenantSigners');
  }

  public onFormValidityChange(isValid: boolean) {
    this.form().setErrors(isValid ? null : { missingFields: true });
  }

  public get isButtonDisabled() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form().get('confirmData').value;
  }

  public get isSendButtonDisabled() {
    return this.form().get('messageLandlord').value.length <= 0;
  }

  public onIncorrectTenantData() {
    this.onSendMessage.emit();
  }
}
