import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import {
  AuthTokenService,
  ChangeLocale,
  CONFIRM_EMAIL,
  ConfirmEmail,
  ConfirmEmailFail,
  ConfirmEmailSuccess,
  errorMessageParser,
  getCurrentLocale,
  getRedirectUrl,
  Go,
  KeycloakTenantService,
  LocalStorageService,
  LogoutReasons,
  ModalService,
  NotificationService,
  SessionStorageService,
  ShowError,
  ShowInfo,
  UserLogoutSuccess,
  UserUpdateLastLogin,
  VERIFY_EMAIL,
  VerifyEmail,
  VerifyEmailFail,
  VerifyEmailSuccess,
  WINDOW_REF
} from '@ui/legacy-lib';

import { PropertySearcherUser, PropertyType } from '@ui/shared/models';

import moment from 'moment';

import { of } from 'rxjs';
import {
  catchError,
  delay,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import * as fromSearchProfileActions from '../search-profiles/search-profiles.actions';
import { AuthService, UserFacade } from '../../core';
import {
  dialogConfig,
  MainPageNavigation,
  notificationConfig,
  storageKeys
} from '../../config';
import { SearchProfileDetailsModalComponent } from '../../components';
import { getProperty } from '../apply';
import * as fromSelectors from './user.selectors';
import * as fromActions from './user.actions';

@Injectable()
export class UserEffects {
  private actions$ = inject(Actions);
  loadUserDataFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadUserDataFail>(fromActions.LOAD_USER_DATA_FAIL),
      map(() => {
        return new fromActions.LoadUserDataSuccess({});
      })
    )
  );
  changePreferredLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangePreferredLanguageSuccess>(
        fromActions.CHANGE_PREFERRED_LANGUAGE_SUCCESS
      ),
      map(action => {
        return action.reload
          ? new ChangeLocale(action.languageCode)
          : { type: 'NO_ACTION' };
      }),
      // delay added to provide user enough time to see the message
      delay(3000)
    )
  );
  private userFacade = inject(UserFacade);
  firstSocialLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.CheckFirstSocialLogin>(
        fromActions.CHECK_FIRST_SOCIAL_LOGIN
      ),
      switchMap(({ email }) =>
        this.userFacade.firstSocialLogin(email).pipe(
          mergeMap(isSocialLogin => [
            new fromActions.CheckFirstSocialLoginSuccess({
              isSocialLogin,
              email
            })
          ]),
          catchError(() =>
            of(
              new fromActions.CheckFirstSocialLoginFail({
                isSocialLogin: true,
                email,
                error: true
              })
            )
          )
        )
      )
    )
  );
  saveUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SaveUserData>(fromActions.SAVE_USER_DATA),
      map(action => action.user),
      switchMap(user =>
        this.userFacade.saveUser(user).pipe(
          mergeMap(result => {
            return [
              new fromActions.SaveUserDataSuccess(result),
              new ShowInfo(notificationConfig.user.save.success)
            ];
          }),
          catchError(err => [
            new fromActions.SaveUserDataFail(
              err ? err.message : 'Unexpected error'
            ),
            new ShowError(notificationConfig.user.save.error)
          ])
        )
      )
    )
  );
  changeEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangeEmail>(fromActions.CHANGE_EMAIL),
      switchMap(action =>
        this.userFacade.changeEmail(action.newEmail).pipe(
          mergeMap(() => [
            new fromActions.ChangeEmailSuccess(),
            new ShowInfo(notificationConfig.user.changeEmail.success)
          ]),
          catchError(error => [new fromActions.ChangeEmailFail(error)])
        )
      )
    )
  );
  changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangePassword>(fromActions.CHANGE_PASSWORD),
      switchMap((action: fromActions.ChangePassword) =>
        this.userFacade
          .changePassword(action.password, action.confirmedPassword)
          .pipe(
            mergeMap(() => [
              new fromActions.ChangePasswordSuccess(),
              new ShowInfo(notificationConfig.user.changePassword.success)
            ]),
            catchError(error => [
              new fromActions.ChangeEmailFail(error),
              new ShowError(notificationConfig.user.changePassword.error)
            ])
          )
      )
    )
  );
  setSearchingStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetSearchingStatus>(fromActions.SET_SEARCHING_STATUS),
      switchMap(({ token, isSearching }) =>
        this.userFacade.setSearchingStatus(token, isSearching).pipe(
          mergeMap(() => [
            new fromActions.SetSearchingStatusSuccess(),
            new Go({
              path: [MainPageNavigation.LOGIN],
              query: { isSearching }
            })
          ]),
          catchError(error => [
            new fromActions.ChangeEmailFail(error),
            new ShowError(notificationConfig.user.setSearchingStatus.error),
            new Go({ path: [MainPageNavigation.LOGIN] })
          ])
        )
      )
    )
  );
  unlockAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetSearchingStatus>(fromActions.UNLOCK_ACCOUNT),
      switchMap(({ token }) =>
        this.userFacade.unlockAccount(token).pipe(
          mergeMap(() => [
            new fromActions.UnlockAccountSuccess(),
            new ShowInfo(notificationConfig.user.unlockAccount.success),
            new Go({ path: [MainPageNavigation.LOGIN] })
          ]),
          catchError(error => [
            new fromActions.UnlockAccountFail(error),
            new ShowError(notificationConfig.user.unlockAccount.error),
            new Go({ path: [MainPageNavigation.LOGIN] })
          ])
        )
      )
    )
  );
  leaveInternalTenantPool$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LeaveInternalTenantPool>(
        fromActions.LEAVE_INTERNAL_TENANT_POOL
      ),
      switchMap(() =>
        this.userFacade.LeaveInternalTenantPool().pipe(
          mergeMap(() => [
            new fromActions.LeaveInternalTenantPoolSuccess(),
            new ShowInfo(
              notificationConfig.user.LeaveInternalTenantPool.success
            )
          ]),
          catchError(error => [
            new fromActions.LeaveInternalTenantPoolFail(error),
            new ShowError(notificationConfig.user.LeaveInternalTenantPool.error)
          ])
        )
      )
    )
  );
  assignRegistrationToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.AssignRegistrationToken>(
        fromActions.ASSIGN_REGISTRATION_TOKEN
      ),
      switchMap(({ tokenId }) =>
        this.userFacade.assignRegistrationToken(tokenId).pipe(
          map(() => new fromActions.AssignRegistrationTokenSuccess()),
          catchError(error =>
            of(new fromActions.AssignRegistrationTokenFail(error))
          )
        )
      )
    )
  );
  sagaDataTransfer$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SagaDataTransfer>(fromActions.SAGA_DATA_TRANSFER),
      switchMap(({ accept, token }) =>
        this.userFacade.sagaDataTransfer(accept, token).pipe(
          map(() => new fromActions.SagaDataTransferResponseSuccess()),
          catchError(error => [
            new fromActions.SagaDataTransferResponseFail(error),
            new ShowError(errorMessageParser(error))
          ])
        )
      )
    )
  );
  changePreferredLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.ChangePreferredLanguage>(
        fromActions.CHANGE_PREFERRED_LANGUAGE
      ),
      switchMap((action: fromActions.ChangePreferredLanguage) =>
        this.userFacade.changePreferredLanguage(action.languageCode).pipe(
          mergeMap(() => {
            const actions = [
              new ShowInfo(
                notificationConfig.user.changePreferredLanguage.success
              ),
              new fromActions.ChangePreferredLanguageSuccess(
                action.languageCode,
                action.reload
              )
            ];

            if (!action.reload) {
              actions.shift();
            }

            return actions;
          }),

          catchError(() => [
            new ShowError(notificationConfig.user.changePreferredLanguage.error)
          ])
        )
      )
    )
  );
  isRegisteredToImmomio$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchIsRegisteredAtImmomio>(
        fromActions.FETCH_IS_REGISTERED_AT_IMMOMIO
      ),
      switchMap(() =>
        this.userFacade.fetchIsRegisteredAtImmomio().pipe(
          map(
            result => new fromActions.FetchIsRegisteredAtImmomioSuccess(result)
          ),
          catchError(error =>
            of(new fromActions.FetchIsRegisteredAtImmomioFail(error))
          )
        )
      )
    )
  );
  registerResident$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.RegisterResident>(fromActions.REGISTER_RESIDENT),
      switchMap(() =>
        this.userFacade.registerResident().pipe(
          map(() => new fromActions.RegisterResidentSuccess()),
          catchError(error => of(new fromActions.RegisterResidentFail(error)))
        )
      )
    )
  );
  private authService = inject(AuthService);
  private authTokenService = inject(AuthTokenService);
  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConfirmEmail>(CONFIRM_EMAIL),
      switchMap(action =>
        this.userFacade.confirmEmail(action.token).pipe(
          tap(() => {
            /* this removeToken is needed cause after confirmation of email the token change.
             So we need to delete the old token and send user to login page.
             without it the issues : ART-13047
             */
            this.authTokenService.removeToken();
          }),
          mergeMap(() => [
            new ConfirmEmailSuccess(),
            new Go({
              path: [MainPageNavigation.LOGIN]
            })
          ]),
          catchError(error => [
            new ConfirmEmailFail(error),
            new Go({
              path: [MainPageNavigation.LOGIN]
            }),
            new ShowError(notificationConfig.user.confirmEmail.error)
          ])
        )
      )
    )
  );
  private store = inject(Store);
  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.Register>(fromActions.REGISTER),
      withLatestFrom(this.store.select(getProperty)),
      switchMap(([{ userData }, property]) => {
        const { brandedCustomerId, ...rest } = userData;
        return this.authService
          .register({
            ...rest,
            customerId: property?.customer?.id || brandedCustomerId,
            propertyId: property?.id
          })
          .pipe(
            mergeMap(() => {
              const showGarageSearchProfileModal =
                userData?.searchProfile?.propertyType === PropertyType.GARAGE;

              return [
                new fromActions.RegisterSuccess(),
                new Go({
                  path: [MainPageNavigation.EMAIL_VERIFICATION_PENDING],
                  query: {
                    justRegistered: true,
                    showGarageSearchProfileModal
                  },
                  extras: {
                    skipLocationChange: true,
                    replaceUrl: true
                  }
                })
              ];
            }),
            catchError(err =>
              of(
                new fromActions.RegisterFail(new Error(errorMessageParser(err)))
              )
            )
          );
      })
    )
  );
  resendEmailVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RESEND_VERIFICATION_EMAIL),
      withLatestFrom(this.store.select(fromSelectors.getUserId)),
      switchMap(([{ token }, userId]) =>
        this.userFacade.resendEmailVerification(userId, token).pipe(
          map(
            () =>
              new ShowInfo(notificationConfig.user.verifyEmailRequested.success)
          ),
          catchError(error => [
            new fromActions.ResendVerificationEmailFail(error)
          ])
        )
      )
    )
  );
  fetchPreferredLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchPreferredLanguage>(
        fromActions.FETCH_PREFERRED_LANGUAGE
      ),
      withLatestFrom(this.store.select(getCurrentLocale)),
      switchMap(([_, currentLanguage]) => {
        return this.userFacade.fetchPreferredLanguage().pipe(
          mergeMap(preferredLanguage => {
            const actions: Action[] = [
              new fromActions.FetchPreferredLanguageSuccess(preferredLanguage)
            ];

            if (preferredLanguage !== currentLanguage) {
              actions.unshift(
                new ShowInfo(
                  notificationConfig.user.changePreferredLanguage.reloading
                )
              );
            }

            return actions;
          }),
          catchError(() => [
            new ShowError(notificationConfig.user.fetchPreferredLanguage.error)
          ])
        );
      })
    )
  );
  fetchPreferredLanguageSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchPreferredLanguageSuccess>(
        fromActions.FETCH_PREFERRED_LANGUAGE_SUCCESS
      ),
      withLatestFrom(this.store.select(getCurrentLocale)),
      filter(
        ([{ languageCode }, currentLanguage]) =>
          languageCode !== currentLanguage
      ),
      map(([{ languageCode }]) => {
        return new ChangeLocale(languageCode);
      }),
      // delay added to provide user enough time to see the message
      delay(3000)
    )
  );
  private sessionStorage = inject(SessionStorageService);
  deleteUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteUserSuccess>(fromActions.DELETE_USER_SUCCESS),
      map(({ redirectUri }) => {
        this.sessionStorage.removeItem(
          storageKeys.redirectedToNewHomeAfterLogin
        );

        this.sessionStorage.removeItem('nonce');
        this.sessionStorage.removeItem('state');
        this.authTokenService.removeToken();

        return new UserLogoutSuccess(redirectUri);
      })
    )
  );
  verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(VERIFY_EMAIL),
      map((action: VerifyEmail) => action.token),
      switchMap(token =>
        this.userFacade.verifyEmail(token).pipe(
          tap(() =>
            this.sessionStorage.setItem(
              storageKeys.emailVerifiedInCurrentSession,
              true
            )
          ),
          mergeMap(() => [
            new VerifyEmailSuccess(),
            new Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            })
          ]),
          catchError(error => [
            new VerifyEmailFail(error),
            new ShowError(
              errorMessageParser(
                error,
                notificationConfig.user.verifyEmail.error
              )
            ),
            new Go({
              path: [
                MainPageNavigation.PROPERTIES,
                MainPageNavigation.APPLICATIONS
              ]
            })
          ])
        )
      )
    )
  );
  private localStorage = inject(LocalStorageService);
  private modalService = inject(ModalService);
  private notificationService = inject(NotificationService);
  openActivenessModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenActivenessModal>(
          fromActions.OPEN_ACTIVENESS_MODAL
        ),
        tap(({ titleMessage, message, okButtonMessage }) => {
          this.notificationService.showInfoModal(
            titleMessage,
            message,
            okButtonMessage
          );
        })
      ),
    { dispatch: false }
  );
  private keycloakService = inject(KeycloakTenantService);
  loadUserData$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadUserData>(fromActions.LOAD_USER_DATA),
      switchMap(() => {
        void this.keycloakService.refresh();
        return this.userFacade.loadUser().pipe(
          tap(user => this.handleSearchConfirmation(user)),
          mergeMap(user => [
            new fromActions.LoadUserDataSuccess(user),
            new UserUpdateLastLogin()
          ]),
          catchError(err =>
            of(
              new fromActions.LoadUserDataFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        );
      })
    )
  );
  private windowRef = inject(WINDOW_REF);
  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.DeleteUser>(fromActions.DELETE_USER),
      switchMap(() => {
        const session_state = this.authTokenService.getToken()?.session_state;
        const redirectUrl = getRedirectUrl(
          this.windowRef.location.toString(),
          '/login',
          {
            queryParams: { reason: LogoutReasons.USER_PROFILE_DELETED },
            pathAfterAuth: ''
          }
        );

        return this.userFacade.deleteUser(session_state, redirectUrl).pipe(
          mergeMap(res => [new fromActions.DeleteUserSuccess(res.redirectUri)]),
          catchError(err =>
            of(
              new fromActions.DeleteUserFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        );
      })
    )
  );

  private handleSearchConfirmation(user: PropertySearcherUser) {
    const userActiveness = this.localStorage.getItem(
      storageKeys.userActivenessSelection
    );

    if (userActiveness) {
      this.localStorage.removeItem(storageKeys.userActivenessSelection);
      const title = dialogConfig.userActiveness.title;
      const message = userActiveness.isSearching
        ? dialogConfig.userActiveness.message_active
        : dialogConfig.userActiveness.message_not_active;
      const okButton = dialogConfig.userActiveness.okButtonMessage;
      if (!userActiveness.isSearching) {
        this.store.dispatch(
          new fromSearchProfileActions.DeleteAllSearchProfiles()
        );
      }
      this.store.dispatch(
        new fromActions.OpenActivenessModal(title, message, okButton)
      );
    } else {
      if (moment().isBefore(moment(user.searchUntil))) return;

      const redirectAction = new Go({
        path: [MainPageNavigation.SEARCH_PROFILES]
      });
      const confirmation = this.modalService.openConfirmation({
        data: dialogConfig.searchProfile.renew,
        backdrop: 'static',
        keyboard: false
      });

      confirmation
        .onClose()
        .subscribe(() => this.store.dispatch(redirectAction));

      confirmation.onDismiss().subscribe(() => {
        this.store.dispatch(redirectAction);

        this.store.dispatch(
          new fromSearchProfileActions.DeleteAllSearchProfiles()
        );
        this.modalService.open(SearchProfileDetailsModalComponent);
      });
    }
  }
}
