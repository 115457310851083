<ul class="main-navigation navbar-nav row col">
  @for (item of visibleItems; track item) {
    @switch (item?.children?.length > 0) {
      @case (false) {
        <app-main-navigation-item role="listitem" [item]="item">
          @if (unreadCount() > 0 && item.name === 'messenger') {
            <span class="unread-counter">{{ unreadCount() }}</span>
          }
        </app-main-navigation-item>
      }
      @case (true) {
        <li class="nav-item d-inline-block" ngbDropdown>
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            ngbDropdownToggle
          >
            {{ item.label | translate }}
            <img src="/assets/images/icons/icon-dropdown.svg" />
          </a>
          <div
            ngbDropdownMenu
            class="dropdown-menu"
            aria-labelledby="navbarDropdown"
          >
            <div class="dropdown-list">
              @for (child of item.children; track child) {
                <a class="dropdown-item" routerLink="{{ child.link }}">
                  {{ child.label | translate }}
                </a>
              }
            </div>
          </div>
        </li>
      }
    }
  }
</ul>
<hr class="m0 mb30" />
<app-footer-v2 [links]="footerLinks()"></app-footer-v2>
