import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthGuardHelperService } from '../services/auth-guard-helper.service';

export const AuthGuard: CanActivateFn = (route, state) => {
  return inject(AuthGuardHelperService).ensureUserIsAuthenticatedAndVerified(
    route,
    state.url
  );
};
