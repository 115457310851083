import { NgModule } from '@angular/core';

import { provideLottieOptions } from 'ngx-lottie';

@NgModule({
  providers: [
    provideLottieOptions({
      player: () => import('lottie-web')
    })
  ]
})
export class ComponentsModule {}
