import { Component, input, output } from '@angular/core';
import {
  ApplicantStatus,
  PropertyApplicationStatus,
  PropertyMatchBean
} from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { ButtonComponent, DateTimePipe } from '@ui/legacy-lib';
import { IntentButtonsComponent } from '../intent-buttons/intent-buttons.component';

@Component({
  selector: 'app-property-action-button',
  templateUrl: './property-action-button.component.html',
  styleUrls: ['./property-action-button.component.scss'],

  imports: [
    SvgIconComponent,
    IntentButtonsComponent,
    TranslateModule,
    DateTimePipe,
    ButtonComponent
  ]
})
export class PropertyActionButtonComponent {
  readonly item = input<PropertyMatchBean>(undefined);
  readonly disabled = input<boolean>(undefined);
  readonly profileComplete = input<boolean>(undefined);

  readonly apply = output<PropertyMatchBean>();
  readonly editProfile = output<void>();
  readonly viewInvitation = output<PropertyMatchBean>();
  readonly noFittingAppointment = output<void>();
  readonly setIntent = output<PropertyApplicationStatus>();
  readonly viewAppointment = output<PropertyMatchBean>();

  get buttonType() {
    return this.item()?.status || ApplicantStatus.NEW_MATCH;
  }

  public onApply() {
    this.apply.emit(this.item());
  }

  public onEditProfile() {
    this.editProfile.emit();
  }

  public onViewInvitation() {
    this.viewInvitation.emit(this.item());
  }

  public onSetIntent(intent: PropertyApplicationStatus) {
    this.setIntent.emit(intent);
  }

  public onViewAppointment() {
    this.viewAppointment.emit(this.item());
  }

  public onNoFittingAppointment() {
    this.noFittingAppointment.emit();
  }
}
