import {
  AfterViewInit,
  Component,
  forwardRef,
  input,
  OnInit,
  output
} from '@angular/core';
import {
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  AppFormFieldControl,
  BaseControl,
  FormFieldComponent,
  SlideToggleComponent
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-confirmation-info',
  templateUrl: './confirmation-info.component.html',
  styleUrls: ['./confirmation-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmationInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmationInfoComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => ConfirmationInfoComponent)
    }
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldComponent,
    SlideToggleComponent
  ]
})
export class ConfirmationInfoComponent
  extends BaseControl<boolean>
  implements OnInit, AfterViewInit
{
  private _touched = false;
  private _errors = null;

  readonly infoText = input<string>(undefined);
  readonly linkText = input<string>(undefined);

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  readonly onLinkClicked = output();

  public field: FormControl;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  ngOnInit(): void {
    this.field = new FormControl(false, [Validators.requiredTrue]);

    this.field.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
      this.errors = this.validate();
      this.stateChanges.next();
    });
  }

  // This class implements BaseControl with heavy overwrites.
  // Remove this method from the class once this class
  // implements proper usage of ngControl.
  public ngAfterViewInit(): void {
    const outerControl = this.injector.get(NgControl).control;
    outerControl.markAsTouched = () => {
      if (!this.touched) {
        this.touched = true;
        this.ngTouched = true;
        this.field.markAsTouched();
        this.field.updateValueAndValidity();
      }
    };
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  writeValue(value: any): void {
    this.field.patchValue(value);
  }

  public validate() {
    return this.field?.valid ? null : { required: true };
  }

  public linkClicked() {
    this.onLinkClicked.emit();
  }
}
