import { Component, input, OnInit, output } from '@angular/core';

import {
  ActionState,
  AttachmentsComponent,
  ButtonComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  HeadingComponent
} from '@ui/legacy-lib';
import { Attachment } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseSchufaStep } from '../../base-schufa-step';
import { SchufaDeliveryResponseData } from '../../../../core/queries';
import { SendSchufaDelivery } from '../../../../models';

@Component({
  selector: 'app-schufa-finish',
  templateUrl: './schufa-finish.component.html',
  styleUrls: ['./schufa-finish.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HeadingComponent,
    ButtonComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    AttachmentsComponent
  ]
})
export class SchufaFinishComponent extends BaseSchufaStep implements OnInit {
  readonly deliveryActionState = input<ActionState>(undefined);
  readonly deliveryData = input<SendSchufaDelivery>(undefined);
  readonly deliveryResponse = input<SchufaDeliveryResponseData>(undefined);
  readonly isLoggedIn = input<boolean>(undefined);
  readonly downloadCreditReportPDF = output<string>();
  readonly uploadCreditReport = output<Attachment>();
  readonly login = output();
  readonly finishSchufa = output();

  get hasError() {
    const deliveryResponse = this.deliveryResponse();
    return (
      (deliveryResponse.error && deliveryResponse.error.length > 0) ||
      deliveryResponse.status.description === 'Identifikations fehlgeschlagen'
    ); // error description is german
  }

  get creditReportControl() {
    return this.form().get('creditReport');
  }

  public onDownloadCreditReportPDF(url: string) {
    this.downloadCreditReportPDF.emit(url);
  }

  public onUploadCreditReport() {
    this.uploadCreditReport.emit(this.form().value.creditReport);
  }

  public onFinish() {
    this.finishSchufa.emit();
  }

  public onLogin() {
    this.login.emit();
  }
}
