import { Component, inject, input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  AppInputDirective,
  ButtonComponent,
  CheckboxComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  LogoComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component,
  PasswordComponent
} from '@ui/legacy-lib';

@Component({
  selector: 'app-guest-register-modal',
  templateUrl: './guest-register-modal.component.html',
  styleUrls: ['./guest-register-modal.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    ModalV2Component,
    ModalContentComponent,
    LogoComponent,
    FormFieldLabelComponent,
    FormFieldComponent,
    AppInputDirective,
    PasswordComponent,
    CheckboxComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class GuestRegisterModalComponent implements OnInit {
  private ngbActiveModal = inject(NgbActiveModal);
  private formBuilder = inject(FormBuilder);

  public email = input<string>(undefined);
  public declareIntent = input<boolean>(false);
  public isApplying = input<boolean>(false);
  public itpCustomerName = input<string>(undefined);

  public form = this.formBuilder.group({
    email: [''],
    password: ['', Validators.required],
    acceptPolicies: [false, Validators.requiredTrue]
  });

  ngOnInit() {
    this.form.patchValue({
      email: this.email()
    });
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public continueAsGuest() {
    this.ngbActiveModal.close();
  }

  public register() {
    if (this.form.invalid) return;
    this.ngbActiveModal.close(this.form.value);
  }
}
