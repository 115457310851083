<app-modal-v2>
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-header">
        <app-button [type]="'link'" (clickEvent)="cancel()">{{
          'general.cancel_a' | translate
        }}</app-button>
      </div>
      <div class="modal-body confirm-apply__body">
        @if (profileComplete()) {
          <p class="title-m confirm-apply__title">
            {{ 'schufa.schufa_info' | translate }}
          </p>
          <app-embedded-link
            class="default-s confirm-apply__message"
            [contentKey]="'schufa.schufa_reports_information'"
            [linkTarget]="'/static/schufa-info'"
            [linkTextKey]="'schufa.info_page_link'"
          >
          </app-embedded-link>
          @if (showSchufaLink()) {
            <a routerLink="/service/schufa/personalInformation" target="_blank"
              >{{ 'appointment.get_schufa_now_l' | translate }}!</a
            >
          }
        } @else {
          <div class="profile-completeness__title mb15">
            {{ 'profile.profile_complete_title_l' | translate }}
          </div>
          <div class="profile-completeness__description mb25">
            {{ 'profile.profile_complete_descritpion_l' | translate }}
          </div>
          @if (showProfileCompleteness()) {
            <div class="uppercase-s profile-completeness__percentage">
              {{
                'profile.profile_complete_apply_l'
                  | translate
                    : { completeness: (profileCompleteness$() | async) }
              }}
            </div>
            <div class="profile-completeness__indicator">
              <div class="profile-completeness__bar"></div>
              <div
                class="profile-completeness__progress"
                [ngStyle]="{ width: (profileCompleteness$() | async) + '%' }"
              ></div>
            </div>
          }
        }
      </div>
    </app-modal-content>

    @if (showFooter()) {
      <app-modal-footer>
        <div class="d-flex align-items-center justify-content-center mb-3">
          <div>
            @if (!profileComplete()) {
              <app-button
                class="col p0 d-inline-block"
                [type]="'primary-inverted'"
                [elevation]="1"
                (clickEvent)="onEditProfile()"
                >{{ 'property.complete_profile_a' | translate }}</app-button
              >
            }

            <app-button
              class="col pr0 pl5 ms-3 d-inline-block"
              [class.pr5]="!profileComplete()"
              (clickEvent)="apply()"
              [elevation]="profileComplete() ? 0 : 1"
              [type]="'primary'"
              >{{ 'property.apply_now_a' | translate }}</app-button
            >
          </div>
        </div>
      </app-modal-footer>
    }
  </div>
</app-modal-v2>
