import { Component, input, OnInit } from '@angular/core';
import { DigitalContractWorkflowState } from '@ui/shared/models';
import {
  AppInputDirective,
  BaseStep,
  FormFieldComponent,
  FormFieldLabelComponent
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-contract-code-verification',
  templateUrl: './contract-code-verification.component.html',
  styleUrls: ['./contract-code-verification.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldComponent,
    FormFieldLabelComponent,
    AppInputDirective
  ]
})
export class ContractCodeVerificationComponent
  extends BaseStep
  implements OnInit
{
  readonly numberOfDigits = input<number>(undefined);
  readonly iban = input<string>(undefined);

  ngOnInit(): void {
    super.ngOnInit();
  }

  public onFormValidityChange(isValid: boolean) {
    this.form().setErrors(isValid ? null : { listIsEmpty: true });
  }

  public get isError() {
    return (
      this.aesWorkflowState === DigitalContractWorkflowState.AES_CODE_FAILED
    );
  }

  public get notAllowed() {
    return (
      this.aesWorkflowState ===
      DigitalContractWorkflowState.AES_CODE_NOT_ALLOWED
    );
  }

  public get isRetry() {
    return (
      this.aesWorkflowState === DigitalContractWorkflowState.AES_CODE_RETRY
    );
  }

  public get getIban() {
    const iban = this.iban();
    return iban ? iban : '-';
  }

  private get aesWorkflowState() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form().get('aesWorkflowState').value;
  }
}
