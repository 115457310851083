import {
  AvailableLanguageCodesEnum,
  PropertySearcherUser,
  SocialLogin,
  UserRegisteredPlatform
} from '@ui/shared/models';

import { ActionState, ActionStateCreator } from '@ui/legacy-lib';

import * as fromActions from './user.actions';

export interface UserState {
  userData: PropertySearcherUser;
  userDataState: ActionState;
  savingUserDataActionState: ActionState;
  verificationEmailResent: boolean;
  registerActionState: ActionState;
  changeEmailActionState: ActionState;
  changePasswordActionState: ActionState;
  setSearchingStatusActionState: ActionState;
  socialLogin: SocialLogin;
  preferredLanguage: AvailableLanguageCodesEnum;
  sagaDataTransferActionState: ActionState;
  isRegisteredAtImmomio: UserRegisteredPlatform;
  isRegisteredAtImmomioActionState: ActionState;
  registerResidentActionState: ActionState;
}

export const initialState: UserState = {
  userData: null,
  userDataState: ActionStateCreator.create(),
  savingUserDataActionState: ActionStateCreator.create(),
  verificationEmailResent: false,
  registerActionState: ActionStateCreator.create(),
  changeEmailActionState: ActionStateCreator.create(),
  changePasswordActionState: ActionStateCreator.create(),
  setSearchingStatusActionState: ActionStateCreator.create(),
  socialLogin: null,
  preferredLanguage: AvailableLanguageCodesEnum.DE,
  sagaDataTransferActionState: ActionStateCreator.create(),
  isRegisteredAtImmomio: null,
  isRegisteredAtImmomioActionState: ActionStateCreator.create(),
  registerResidentActionState: ActionStateCreator.create()
};

export function reducer(
  state = initialState,
  action: fromActions.UserAction
): UserState {
  switch (action.type) {
    case fromActions.LOAD_USER_DATA: {
      return {
        ...state,
        userDataState: ActionStateCreator.onStart()
      };
    }

    case fromActions.LOAD_USER_DATA_SUCCESS: {
      return {
        ...state,
        userData: action.user,
        userDataState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.LOAD_USER_DATA_FAIL: {
      return {
        ...state,
        userDataState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SAVE_USER_DATA: {
      return {
        ...state,
        savingUserDataActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SAVE_USER_DATA_SUCCESS: {
      return {
        ...state,
        savingUserDataActionState: ActionStateCreator.onSuccess(),
        userData: action.user
      };
    }

    case fromActions.SAVE_USER_DATA_FAIL: {
      return {
        ...state,
        savingUserDataActionState: ActionStateCreator.onError(action.error),
        userDataState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.REGISTER: {
      return {
        ...state,
        registerActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.REGISTER_FAIL: {
      return {
        ...state,
        registerActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.REGISTER_SUCCESS: {
      return {
        ...state,
        registerActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.RESEND_VERIFICATION_EMAIL: {
      return {
        ...state,
        verificationEmailResent: true
      };
    }

    case fromActions.CHANGE_EMAIL: {
      return {
        ...state,
        changeEmailActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.CHANGE_EMAIL_FAIL: {
      return {
        ...state,
        changeEmailActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.CHANGE_EMAIL_SUCCESS: {
      return {
        ...state,
        changeEmailActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.CHANGE_PASSWORD: {
      return {
        ...state,
        changePasswordActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.CHANGE_PASSWORD_FAIL: {
      return {
        ...state,
        changePasswordActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePasswordActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.SET_SEARCHING_STATUS: {
      return {
        ...state,
        setSearchingStatusActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SET_SEARCHING_STATUS_FAIL: {
      return {
        ...state,
        setSearchingStatusActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SET_SEARCHING_STATUS_SUCCESS: {
      return {
        ...state,
        setSearchingStatusActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.CHECK_FIRST_SOCIAL_LOGIN_SUCCESS: {
      return {
        ...state,
        socialLogin: action.socialLogin
      };
    }

    case fromActions.CHECK_FIRST_SOCIAL_LOGIN_FAIL: {
      return {
        ...state,
        socialLogin: action.socialLogin
      };
    }

    case fromActions.SAGA_DATA_TRANSFER: {
      return {
        ...state,
        sagaDataTransferActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.SAGA_DATA_TRANSFER_RESPONSE_FAIL: {
      return {
        ...state,
        sagaDataTransferActionState: ActionStateCreator.onError(action.error)
      };
    }

    case fromActions.SAGA_DATA_TRANSFER_RESPONSE_SUCCESS: {
      return {
        ...state,
        sagaDataTransferActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.FETCH_PREFERRED_LANGUAGE_SUCCESS: {
      return {
        ...state,
        preferredLanguage: action.languageCode
      };
    }

    case fromActions.FETCH_IS_REGISTERED_AT_IMMOMIO: {
      return {
        ...state,
        isRegisteredAtImmomioActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.FETCH_IS_REGISTERED_AT_IMMOMIO_SUCCESS: {
      return {
        ...state,
        isRegisteredAtImmomio: action.userRegisteredPlatform,
        isRegisteredAtImmomioActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.FETCH_IS_REGISTERED_AT_IMMOMIO_FAIL: {
      return {
        ...state,
        isRegisteredAtImmomioActionState: ActionStateCreator.onError(
          action.error
        )
      };
    }

    case fromActions.REGISTER_RESIDENT: {
      return {
        ...state,
        registerResidentActionState: ActionStateCreator.onStart()
      };
    }

    case fromActions.REGISTER_RESIDENT_SUCCESS: {
      return {
        ...state,
        isRegisteredAtImmomio: null,
        isRegisteredAtImmomioActionState: ActionStateCreator.create(),
        registerResidentActionState: ActionStateCreator.onSuccess()
      };
    }

    case fromActions.REGISTER_RESIDENT_FAIL: {
      return {
        ...state,
        registerResidentActionState: ActionStateCreator.onError(action.error)
      };
    }

    default:
      return state;
  }
}

export const getUserData = (state: UserState) => state.userData;
export const getUserDataSavingActionState = (state: UserState) =>
  state.savingUserDataActionState;
export const getVerificationEmailResent = (state: UserState) =>
  state.verificationEmailResent;

export const getRegisterActionState = (state: UserState) =>
  state.registerActionState;

export const getChangeEmailActionState = (state: UserState) =>
  state.changeEmailActionState;
export const getChangePasswordActionState = (state: UserState) =>
  state.changePasswordActionState;

export const getSetSearchingStatusActionState = (state: UserState) =>
  state.setSearchingStatusActionState;

export const getSocialLogin = (state: UserState) => state.socialLogin;

export const getSagaDataTransferActionState = (state: UserState) =>
  state.sagaDataTransferActionState;

export const getPreferredLanguage = (state: UserState) =>
  state.preferredLanguage;

export const getIsRegisteredAtImmomio = (state: UserState) =>
  state.isRegisteredAtImmomio;

export const getIsRegisteredAtImmomioActionState = (state: UserState) =>
  state.isRegisteredAtImmomioActionState;

export const getRegisterResidentActionState = (state: UserState) =>
  state.registerResidentActionState;
