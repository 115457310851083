import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'app-main-navigation-item',
  templateUrl: './main-navigation-item.component.html',
  styleUrls: ['./main-navigation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [RouterLinkActive, RouterLink, SvgIconComponent, TranslateModule]
})
export class MainNavigationItemComponent {
  readonly item = input<NavigationItem>(undefined);
  public hover = false;
}
