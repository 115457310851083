import { inject, Injectable } from '@angular/core';
import {
  AuthToken,
  AuthTokenService,
  DeepLinkService,
  KeycloakTenantWebService,
  LocalStorageService
} from '@ui/legacy-lib';
import {
  ActivatedRouteSnapshot,
  RedirectCommand,
  Router
} from '@angular/router';
import { catchError, filter, from, map, Observable, of, take } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import {
  getIsRegisteredAtImmomio,
  getIsRegisteredAtImmomioActionState,
  getUserData
} from '../../+state/user/user.selectors';
import { MainPageNavigation, NAVIGATION_LINK, storageKeys } from '../../config';

import {
  FetchIsRegisteredAtImmomio,
  getUserDataState,
  LoadUserData
} from '../../+state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardHelperService {
  private authTokenService = inject(AuthTokenService);
  private deepLinkService = inject(DeepLinkService);
  private keycloakWebService = inject(KeycloakTenantWebService);
  private localStorageService = inject(LocalStorageService);
  private store = inject(Store);
  private router = inject(Router);
  private location = inject(Location);

  private getAuthToken(route: ActivatedRouteSnapshot): AuthToken {
    return route?.fragment
      ? this.deepLinkService.getSSOParams(route)
      : this.authTokenService.getToken();
  }

  private continueKeycloakLogin(
    authToken: AuthToken,
    route: ActivatedRouteSnapshot,
    redirectToUrl?: string
  ): Observable<boolean | RedirectCommand> {
    const redirectUri = this.localStorageService.getItem<string>(
      storageKeys.redirectUri
    );
    return this.keycloakWebService.keycloakInitialized().pipe(
      filter(initialized => initialized),
      take(1),
      switchMap(() => {
        const keycloakInstanceToken =
          this.keycloakWebService.getAuthTokenFromKeycloakInstance();
        if (keycloakInstanceToken) {
          return this.continueLogin(
            keycloakInstanceToken,
            route,
            redirectToUrl
          );
        } else {
          return from(
            this.keycloakWebService.continueLoginWithCode({
              code: authToken.code,
              redirectUri
            })
          ).pipe(
            switchMap(authToken =>
              this.continueLogin(authToken, route, redirectToUrl)
            )
          );
        }
      })
    );
  }

  private continueLogin(
    authToken: AuthToken,
    route: ActivatedRouteSnapshot,
    redirectToUrl?: string
  ): Observable<boolean | RedirectCommand> {
    this.localStorageService.removeItem(storageKeys.redirectUri);
    this.authTokenService.setToken(authToken);
    return this.isRegisteredAtImmomio(route, redirectToUrl);
  }

  public isRegisteredAtImmomio(
    activatedRoute: ActivatedRouteSnapshot,
    redirectToUrl?: string
  ): Observable<boolean | RedirectCommand> {
    this.store.dispatch(new FetchIsRegisteredAtImmomio());

    return this.store.select(getIsRegisteredAtImmomioActionState).pipe(
      filter(actionState => actionState.done || !!actionState.error),
      take(1),
      switchMap(actionState =>
        this.store.select(getIsRegisteredAtImmomio).pipe(
          switchMap(isRegisteredAtImmomio => {
            // Not sure when isRegisteredAtImmomio can be null. Just took that from the old implementation
            if (actionState.error || !isRegisteredAtImmomio) {
              return of(this.logoutUserCommand(redirectToUrl));
            }

            if (!isRegisteredAtImmomio.registeredAtImmomio) {
              return of(
                new RedirectCommand(
                  this.router.createUrlTree(
                    [NAVIGATION_LINK.TERMS_AND_CONDITIONS],
                    {
                      queryParams: activatedRoute.queryParams
                    }
                  ),
                  {
                    skipLocationChange: true
                  }
                )
              );
            }

            return this.fetchUserData(redirectToUrl);
          })
        )
      ),
      catchError(() => {
        return of(this.logoutUserCommand(redirectToUrl));
      })
    );
  }

  private logoutUserCommand(redirectToUrl?: string) {
    // At this point it can happen that an invalid token was set.
    // This should be removed because it will cause issues with the LoginGuard
    // (Infinite redirect loop between LoginGuard and AuthGuard)
    this.authTokenService.removeToken();
    return new RedirectCommand(
      this.router.createUrlTree([MainPageNavigation.LOGIN], {
        queryParams: {
          pathAfterAuth: redirectToUrl
        }
      })
    );
  }

  private fetchUserData(redirectToUrl?: string) {
    return this.store.select(getUserDataState).pipe(
      tap(state => {
        if (!state.pending && !state.done && !state.error) {
          this.store.dispatch(new LoadUserData());
        }
      }),
      filter(state => state.done || !!state.error),
      switchMap(state => {
        if (state.error) {
          this.authTokenService.removeToken();
          return of(this.logoutUserCommand(redirectToUrl));
        }
        return this.isUserVerified(redirectToUrl);
      })
    );
  }

  private isUserVerified(redirectToUrl?: string) {
    return this.store.select(getUserData).pipe(
      take(1),
      map(userData => {
        if (!userData) {
          return this.logoutUserCommand(redirectToUrl);
        }
        if (redirectToUrl.includes('#')) {
          // There's some metadata included in the url by keycloak
          // This HAS to be removed from the URL. If not it will throw an error when you reload the page
          const urlWithoutHash = redirectToUrl.split('#')[0];
          this.location.replaceState(urlWithoutHash);
        }
        if (!userData.emailVerified) {
          return new RedirectCommand(
            this.router.parseUrl(MainPageNavigation.EMAIL_VERIFICATION_PENDING),
            {
              // If the user has used pathAfterAuth, then it will be preserved with this option,
              // if the user tries to refresh the page and logs in again.
              skipLocationChange: true
            }
          );
        }

        return true;
      })
    );
  }

  public ensureUserIsAuthenticatedAndVerified(
    route: ActivatedRouteSnapshot,
    redirectToUrl?: string
  ): Observable<boolean | RedirectCommand> {
    const authToken = this.getAuthToken(route);

    if (authToken?.access_token) {
      return this.isRegisteredAtImmomio(route, redirectToUrl);
    } else if (!authToken?.['code']) {
      // If the user has NOT been redirected from the keycloak login site,
      // then the user is not logged in when no token is set.
      // Redirect him to the login page
      return of(this.logoutUserCommand(redirectToUrl));
    } else {
      // If the user HAS been redirected from the keycloak login site,
      // then the login should continue and the token should be fetched
      return this.continueKeycloakLogin(authToken, route, redirectToUrl);
    }
  }
}
