import { gql } from 'apollo-angular';

import { SearchProfile } from '@ui/shared/models';
import { searchProfilesGQL } from '@ui/legacy-lib';
import { limitedProjectDataFields } from './projects.queries';

export interface SearchProfilesQueryResponse {
  searchProfiles: SearchProfile[];
}

const searchProfileFields = `
  ${searchProfilesGQL}
  projectData {
    ${limitedProjectDataFields}
  }
  manuallyCreated
  deactivated
`;

export const searchProfilesQuery = gql`
  query searchProfiles {
    searchProfiles {
      ${searchProfileFields}
    }
  }
`;

export const saveSearchProfileMutation = gql`
  mutation saveSearchProfile($searchProfile: SearchProfileInput!) {
    saveSearchProfile(searchProfile: $searchProfile) {
      status
      statusText
    }
  }
`;

export const deleteSearchProfileMutation = gql`
  mutation deleteSearchProfile($id: String!) {
    deleteSearchProfile(id: $id) {
      status
      statusText
    }
  }
`;

export const toggleSearchProfileMutation = gql`
  mutation toggleSearchProfile($id: String!, $activate: Boolean) {
    toggleSearchProfile(id: $id, activate: $activate) {
      status
      statusText
    }
  }
`;

export const deleteAllSearchProfilesMutation = gql`
  mutation deleteAllSearchProfiles {
    deleteAllSearchProfiles {
      status
      statusText
    }
  }
`;

export const deactivateAllSearchProfilesMutation = gql`
  mutation deactivateAllSearchProfiles($propertyType: String!) {
    deactivateAllSearchProfiles(propertyType: $propertyType) {
      status
      statusText
    }
  }
`;

export const activateAllSearchProfilesMutation = gql`
  mutation activateAllSearchProfiles($propertyType: String!) {
    activateAllSearchProfiles(propertyType: $propertyType) {
      status
      statusText
    }
  }
`;
