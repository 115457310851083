import { createSelector } from '@ngrx/store';
import { getUserState } from '../reducers';
import * as fromUserReducer from './user.reducers';

export const getUserData = createSelector(
  getUserState,
  fromUserReducer.getUserData
);
export const getUserDataState = createSelector(
  getUserState,
  state => state.userDataState
);
export const getUserDataError = createSelector(
  getUserState,
  state => state.userDataState.error
);
export const getUserDataLoaded = createSelector(
  getUserState,
  state => state.userDataState.done
);
export const getUserDataLoading = createSelector(
  getUserState,
  state => state.userDataState.pending
);
export const getUserDataSavingActionState = createSelector(
  getUserState,
  fromUserReducer.getUserDataSavingActionState
);

export const getUserDataSaved = createSelector(
  getUserDataSavingActionState,
  action => action.done
);
export const getUserDataSaving = createSelector(
  getUserDataSavingActionState,
  action => action.pending
);

export const getUserId = createSelector(getUserData, data => data && data.id);
export const getProfileCompleteness = createSelector(
  getUserData,
  data => data && data.profileCompleteness
);

export const getHasEmailVerified = createSelector(getUserData, userData =>
  Boolean(userData?.emailVerified)
);

export const getVerificationEmailResent = createSelector(
  getUserState,
  fromUserReducer.getVerificationEmailResent
);

export const getUserEmail = createSelector(
  getUserData,
  userData => userData.email
);

export const getRegisterActionState = createSelector(
  getUserState,
  fromUserReducer.getRegisterActionState
);

export const getRegisterError = createSelector(
  getRegisterActionState,
  registerState => registerState.error
);

export const getRegisterDone = createSelector(
  getRegisterActionState,
  registerState => registerState.done
);

export const getRegisterProcessing = createSelector(
  getRegisterActionState,
  registerState => registerState.pending
);

export const getChangeEmailActionState = createSelector(
  getUserState,
  fromUserReducer.getChangeEmailActionState
);

export const getChangePasswordActionState = createSelector(
  getUserState,
  fromUserReducer.getChangePasswordActionState
);

export const getSetSearchingStatusActionState = createSelector(
  getUserState,
  fromUserReducer.getSetSearchingStatusActionState
);

export const getSocialLogin = createSelector(
  getUserState,
  fromUserReducer.getSocialLogin
);

export const getSagaDataTransferActionState = createSelector(
  getUserState,
  fromUserReducer.getSagaDataTransferActionState
);

export const getPreferredLanguage = createSelector(
  getUserState,
  fromUserReducer.getPreferredLanguage
);

export const getIsRegisteredAtImmomio = createSelector(
  getUserState,
  fromUserReducer.getIsRegisteredAtImmomio
);

export const getIsRegisteredAtImmomioActionState = createSelector(
  getUserState,
  fromUserReducer.getIsRegisteredAtImmomioActionState
);

export const getRegisterResidentActionState = createSelector(
  getUserState,
  fromUserReducer.getRegisterResidentActionState
);
