import { Component, inject, input } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  AttachmentsComponent,
  BadgeComponent,
  BaseStep,
  ButtonComponent,
  defaultDocumentsConfig,
  FormFieldComponent,
  FormFieldLabelComponent,
  Go,
  NotificationService,
  WINDOW_REF
} from '@ui/legacy-lib';

import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ENVIRONMENT_CONFIG } from '../../core';
import { MainPageNavigation } from '../../config';

@Component({
  selector: 'app-profile-dk-three',
  templateUrl: './profile-dk-three.component.html',
  styleUrls: ['./profile-dk-three.component.scss'],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    AttachmentsComponent,
    BadgeComponent,
    ButtonComponent
  ]
})
export class ProfileDkThreeComponent extends BaseStep {
  readonly schufaCard = input<boolean>(undefined);
  readonly showCreditScreeningDocumentUploadArea = input<boolean>(undefined);
  readonly isRegister = input<boolean>(undefined);
  public acceptedTypes = defaultDocumentsConfig.allAcceptedTypes;
  public acceptedSize = defaultDocumentsConfig.acceptedDocumentSize;
  private store = inject(Store);
  private notificationService = inject(NotificationService);
  private environment = inject(ENVIRONMENT_CONFIG);
  private windowRef = inject(WINDOW_REF);

  public get creditScoreValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return this.form().get('creditScore').value?.length !== 0;
  }

  public schufaMoreInfo(): void {
    this.notificationService.showInfoModal(
      'schufa.more_info_title_l',
      'schufa.more_info_msg_l',
      'general.ok',
      true
    );
  }

  public onSchufaRegister(): void {
    if (this.isRegister()) {
      // during registration, we don't want the user to loose already entered data,
      // so we open SCHUFA in new tab.
      const url = `${this.environment.property_searcher_base_url}/de/schufa`;
      this.windowRef.open(url, '_blank');
    } else {
      this.store.dispatch(
        new Go({
          path: [
            MainPageNavigation.SERVICE_CENTER,
            MainPageNavigation.SCHUFA,
            'personalInformation'
          ]
        })
      );
    }
  }
}
