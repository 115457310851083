import { Component, OnInit } from '@angular/core';

import { RouterOutlet } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CookiePreference } from '@ui/shared/models';
import {
  CookieBannerComponent,
  fadeOnEnterLeaveAnimation,
  FooterComponent,
  InitCookiesPreference,
  OpenCookieSettingsModal,
  PerformanceWarningBannerComponent,
  SetCookiesPreference
} from '@ui/legacy-lib';

import { AsyncPipe } from '@angular/common';

import { BaseShellComponent } from '../base-shell.component';

@UntilDestroy()
@Component({
  selector: 'app-unauthenticated-shell',
  templateUrl: './unauthenticated-shell.component.html',
  styleUrls: ['./unauthenticated-shell.component.scss'],
  animations: [fadeOnEnterLeaveAnimation],

  imports: [
    RouterOutlet,
    AsyncPipe,
    CookieBannerComponent,
    PerformanceWarningBannerComponent,
    FooterComponent
  ]
})
export class UnauthenticatedShellComponent
  extends BaseShellComponent
  implements OnInit
{
  public ngOnInit() {
    super.ngOnInit();
    // initially load the preference from LS and enable tracking if configured
    this.store.dispatch(new InitCookiesPreference());
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new SetCookiesPreference(payload));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(new OpenCookieSettingsModal(payload, true));
  }
}
