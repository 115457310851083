import { Component, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import {
  ButtonComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';
import { defaultAdvertising } from '../../config/hideTypes';

@Component({
  selector: 'app-appointment-success-modal',
  templateUrl: './appointment-success-modal.component.html',
  styleUrls: ['./appointment-success-modal.component.scss'],
  imports: [
    RouterLink,
    TranslateModule,
    ModalContentComponent,
    ModalV2Component,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class AppointmentSuccessModalComponent {
  readonly schufaCard = input<boolean>(undefined);
  private ngbActiveModal = inject(NgbActiveModal);

  public get showSchufaLink(): boolean {
    return this.schufaCard() ?? defaultAdvertising.schufaCard;
  }

  public close(): void {
    this.ngbActiveModal.close();
  }

  public dismiss(): void {
    this.ngbActiveModal.dismiss();
  }
}
