import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Injector,
  OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { GenericFormGroup } from '@ui/shared/models';

import { TranslateModule } from '@ngx-translate/core';
import {
  AppInputDirective,
  FlatSelectComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  HintComponent
} from '@ui/legacy-lib';
import { ConfirmationInfoComponent } from '../confirmation-info/confirmation-info.component';
import { SchufaIdentityData, SendSchufaDelivery } from '../../../../models';
import { SchufaError } from '../../../../core/queries';

const BOOLEAN_OPTIONS = [
  { name: 'general.new_l', value: false },
  { name: 'general.old_l', value: true }
];

const ID_CARD_IMAGE_NAMES = {
  new: {
    1: 'id_card_new_1',
    2: 'id_card_new_2',
    3: 'id_card_new_3',
    4: 'id_card_new_4'
  },
  old: {
    1: 'id_card_old_1',
    2: 'id_card_old_2',
    3: 'id_card_old_3',
    4: 'id_card_old_4'
  }
};

@UntilDestroy()
@Component({
  selector: 'app-schufa-identification',
  templateUrl: './schufa-identification.component.html',
  styleUrls: ['./schufa-identification.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SchufaIdentificationComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SchufaIdentificationComponent),
      multi: true
    }
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,

    ConfirmationInfoComponent,
    TranslateModule,
    FormFieldLabelComponent,
    FormFieldComponent,
    HintComponent,
    FlatSelectComponent,
    AppInputDirective
  ]
})
export class SchufaIdentificationComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  public form: GenericFormGroup<SchufaIdentityData>;
  public options = BOOLEAN_OPTIONS;
  public deliveryResponseError: SchufaError[];
  public idCardImage: string;
  private fb = inject(FormBuilder);
  private cdr = inject(ChangeDetectorRef);
  private injector = inject(Injector);

  get idCardOld() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('idCardOld').value;
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      iBAN: '',
      idCardOld: false,
      idCardSerialNumber: ['', Validators.required],
      idCardBirthDate: ['', Validators.required],
      idCardExpireDate: ['', Validators.required],
      idCardCheckSum: ['', Validators.required],
      withdrawalAccepted: [false, Validators.requiredTrue]
    });

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });
  }

  public ngAfterViewInit(): void {
    const outerControl = this.injector.get(NgControl).control;
    outerControl.markAsTouched = () => {
      this.form.markAllAsTouched();
    };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(value: SendSchufaDelivery): void {
    this.form.patchValue(value);
  }

  public validate() {
    return !this.form || this.form.valid ? null : { missingFields: true };
  }

  public onFocusIn(id: number) {
    this.idCardImage = ID_CARD_IMAGE_NAMES[this.idCardOld ? 'old' : 'new'][id];
  }

  public onFocusOut() {
    this.idCardImage = null;
  }

  private onChange: (arg) => any = () => null;

  private onTouch: () => any = () => null;
}
