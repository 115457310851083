@if (!personalDataActionState().error) {
  @if (!personalDataActionState().pending) {
    <div class="row container-padding">
      <form
        [formGroup]="form()"
        appFocusFormError
        class="col-12 col-xl-8"
        (ngSubmit)="onSubmit()"
      >
        <div class="schufa__header mb30">
          <app-heading [level]="2" [styleType]="100"
            >{{ 'schufa.form_title_l' | translate }}
          </app-heading>
          <p [innerHTML]="'schufa.form_sub_title_l' | translate"></p>
        </div>
        <div class="row">
          <app-schufa-benefits-card
            class="col-12 col-md-10 u-hide@xl mb30"
          ></app-schufa-benefits-card>
        </div>
        <div class="schufa-body">
          <app-heading [level]="3" [styleType]="200" class="mb25"
            >{{ 'schufa.personal_information_l' | translate }}
          </app-heading>
          <div class="schufa-form-content">
            <div formGroupName="personalData">
              <div class="row">
                <div class="col-6 col-md-3 col-lg-4 col-xl-3 mb20">
                  <app-form-field>
                    <app-form-field-label [showRequiredMarker]="true"
                      >{{ 'SALUTE_L' | translate }}
                    </app-form-field-label>
                    <app-dropdown-select
                      class="form-control"
                      [items]="genderTypes()"
                      formControlName="salutation"
                      required
                    >
                      <div dropdown-button>
                        {{ 'SELECT_SALUTE_L' | translate }}
                      </div>
                      <ng-template let-item
                        >{{ item.label | translate }}
                      </ng-template>
                    </app-dropdown-select>
                  </app-form-field>
                </div>
              </div>
              <div class="row">
                <div class="col-6 mb20">
                  <app-form-field>
                    <app-form-field-label [showRequiredMarker]="true"
                      >{{ 'general.first_name_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="text"
                      required
                      [placeholder]="'forms.type_name' | translate"
                      formControlName="firstName"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
                <div class="col-6 mb20">
                  <app-form-field>
                    <app-form-field-label [showRequiredMarker]="true"
                      >{{ 'general.last_name_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="text"
                      required
                      [placeholder]="'forms.type_name' | translate"
                      formControlName="lastName"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
                <div class="col-6 col-md-3 col-lg-4 col-xl-3 mb20">
                  <app-form-field>
                    <app-form-field-label [showRequiredMarker]="true"
                      >{{ 'general.birthdate_l' | translate }}
                    </app-form-field-label>
                    <app-date
                      required
                      formControlName="dateOfBirth"
                      [startDate]="startDateStruct"
                      [maxDate]="maxDateStruct"
                      [minDate]="minDateStruct"
                    ></app-date>
                  </app-form-field>
                </div>
                <div class="col-12 mb20">
                  <app-form-field>
                    <app-form-field-label [showRequiredMarker]="true"
                      >{{ 'general.email_address_l' | translate }}
                    </app-form-field-label>
                    <input
                      appInput
                      type="email"
                      required
                      placeholder="{{ 'forms.type_email_address' | translate }}"
                      formControlName="email"
                      class="form-control"
                    />
                  </app-form-field>
                </div>
              </div>
            </div>
            <app-schufa-address
              addressTitle="{{ 'schufa.set_address_l' | translate }}"
              class="mt10 mb20 d-block"
              [form]="address"
              [useStreetForSearch]="true"
              [addressHint]="'schufa.set_address_hint_m'"
            ></app-schufa-address>
            <div class="row mb20">
              <div class="col-12 d-flex mb20">
                <app-form-field>
                  <app-form-field-label
                    >{{ 'schufa.last_year_moved_l' | translate }}
                  </app-form-field-label>
                  <app-flat-select
                    [items]="options"
                    formControlName="lastYearMoved"
                    required
                  ></app-flat-select>
                </app-form-field>
                <app-hint class="pl5"
                  >{{ 'schufa.last_year_moved_hint_m' | translate }}
                </app-hint>
              </div>
              @if (lastYearMoved.value) {
                @for (item of preAddressControls; track item; let i = $index) {
                  <app-schufa-address
                    [index]="i"
                    class="col-12 mb20"
                    [form]="item"
                    addressTitle="{{
                      ('schufa.set_last_address_l' | translate) +
                        preAddressCountLabel(preAddressControls.length, i)
                    }}"
                    [useStreetForSearch]="true"
                    [addressHint]="'schufa.set_last_address_hint_m'"
                    [removable]="true"
                    (remove)="removeOption(i)"
                  ></app-schufa-address>
                }
                @if (preAddress.length < 3) {
                  <div class="col-12 mb30">
                    <app-button
                      [type]="'light-bordered'"
                      [iconLeft]="'add'"
                      (clickEvent)="addOption()"
                    >
                      {{ 'schufa.add_add_voraddress_a' | translate }}
                    </app-button>
                  </div>
                }
              }
              <div class="col-12 mt10">
                <app-form-field>
                  <app-confirmation-info
                    class="form-control"
                    formControlName="approvalTermsOfCondition"
                    [infoText]="'schufa.confirm_data_privacy_l'"
                  ></app-confirmation-info>
                </app-form-field>
              </div>
            </div>
          </div>
          <div class="row mb30">
            <div class="col-12 col-sm-6 col-md-7 col-lg-8 order-sm-2">
              <div class="d-flex justify-content-end mb10@xs">
                <app-button
                  class="w-100 u-width-auto@sm-min"
                  [type]="'primary'"
                  [buttonType]="'submit'"
                  >{{ 'schufa.order_continue_a' | translate }}
                </app-button>
              </div>
            </div>
            <div class="col-6 col-md-5 col-lg-4 order-sm-1">
              <app-schufa-certificates></app-schufa-certificates>
            </div>
            <div class="col-6 col-sm-12 order-sm-3">
              <div class="d-flex justify-content-end">
                {{ 'general.mandatory_field_information_l' | translate }}
              </div>
            </div>
          </div>
          <div class="mb20 text-justify schufa__delivery-info">
            {{ 'schufa.delivery_information_l' | translate }}
          </div>
        </div>
      </form>
      <app-schufa-benefits-card
        class="col-0 col-lg-4 u-hide@lg-max"
      ></app-schufa-benefits-card>
    </div>
  } @else {
    <div class="loading-area">
      <app-loading-spinner></app-loading-spinner>
    </div>
  }
} @else {
  @if (!shouldRetry) {
    <div class="schufa__error row">
      <div class="col-12 col-xl-8">
        <app-heading [styleType]="100"
          >{{ 'schufa.error_happen_title_l' | translate }}
        </app-heading>
        <div
          class="mb20"
          [innerHTML]="'schufa.error_happen_message_l' | translate"
        ></div>
        <app-button [type]="'primary'" (clickEvent)="onRetry()"
          >{{ 'schufa.retry_a' | translate }}
        </app-button>
      </div>
    </div>
  } @else {
    <div class="schufa__retry row">
      <div class="col-12 col-xl-8">
        <app-heading [styleType]="100"
          >{{ 'schufa.retry_title_l' | translate }}
        </app-heading>
        <div
          class="mb20"
          [innerHTML]="'schufa.retry_message_l' | translate"
        ></div>
        <app-button [type]="'primary'" (clickEvent)="onRetry()"
          >{{ 'schufa.retry_a' | translate }}
        </app-button>
      </div>
    </div>
  }
}
