import { inject, Injectable } from '@angular/core';

import { Apollo, QueryRef } from 'apollo-angular';
import { Observable, of, zip } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import {
  Attachment,
  CancelAppointmentData,
  DeclareIntentData,
  PropertySearcherDocumentTypes,
  ReasonData
} from '@ui/shared/models';
import { FileUploadService, getResponseValidator } from '@ui/legacy-lib';
import {
  acceptAppointmentMutation,
  applicationRegisterMutation,
  applyAsGuestMutation,
  appointmentAcceptancesCancelMutation,
  brandingQuery,
  BrandingQueryResult,
  CheckGuestApplicationExistsQueryResult,
  checkGuestApplicationQuery,
  customQuestionResponsesQuery,
  CustomQuestionResponsesQueryResult,
  declareIntentMutation,
  deleteQuery,
  guestApplicationQuery,
  GuestApplicationQueryResult,
  GuestApplicationRegisterResponse,
  guestAppointmentsQuery,
  GuestAppointmentsQueryResult,
  GuestMutationResult,
  guestQuestionsQuery,
  GuestQuestionsQueryResponse,
  GuestRegisterResponse,
  registerMutation
} from '../queries/guest-mode.queries';
import { UserData } from '../../models';

@Injectable()
export class GuestModeFacade {
  private apollo = inject(Apollo);
  private fileUploadService = inject(FileUploadService);

  private appointmentsQuery: QueryRef<GuestAppointmentsQueryResult>;
  private applicationQuery: QueryRef<GuestApplicationQueryResult>;

  public checkGuestApplicationExists(email: string, propertyId: string) {
    return this.apollo
      .query({
        query: checkGuestApplicationQuery,
        variables: { email, propertyId },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<CheckGuestApplicationExistsQueryResult>()),
        map(response => response.data.checkGuestApplication)
      );
  }

  public loadGuestAppointments(token: string) {
    this.appointmentsQuery =
      this.apollo.watchQuery<GuestAppointmentsQueryResult>({
        query: guestAppointmentsQuery,
        variables: { token },
        fetchPolicy: 'no-cache'
      });

    return this.appointmentsQuery.valueChanges.pipe(
      filter(response => !response.loading || !!response.data),
      map(response => response.data.guestAppointments)
    );
  }

  public loadGuestApplication(token: string) {
    this.applicationQuery = this.apollo.watchQuery<GuestApplicationQueryResult>(
      {
        query: guestApplicationQuery,
        variables: { token },
        fetchPolicy: 'no-cache'
      }
    );

    return this.applicationQuery.valueChanges.pipe(
      tap(getResponseValidator<GuestApplicationQueryResult>()),
      map(res => res.data.guestApplication)
    );
  }

  public loadCustomQuestionResponses(token: string) {
    return this.apollo
      .query({
        query: customQuestionResponsesQuery,
        variables: { token },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<CustomQuestionResponsesQueryResult>()),
        map(res => res.data.customQuestionResponses)
      );
  }

  public loadBranding(token: string) {
    return this.apollo
      .query({
        query: brandingQuery,
        variables: { token },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<BrandingQueryResult>()),
        map(res => res.data.branding)
      );
  }

  public applyAsGuest(input, token) {
    return this.uploadUserFiles(input.profileData, token).pipe(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      map(profileData => ({ ...input, profileData })),
      switchMap(guestData =>
        this.apollo
          .mutate({
            mutation: applyAsGuestMutation,
            variables: { guestData, token }
          })
          .pipe(tap(getResponseValidator<GuestMutationResult>()))
      )
    );
  }

  public applicationRegister(tenantData: UserData, token: string) {
    return this.uploadUserFiles(tenantData.userProfileData, token).pipe(
      map(userProfileData => ({ ...tenantData, userProfileData })),
      switchMap(userData =>
        this.apollo
          .mutate({
            mutation: applicationRegisterMutation,
            variables: { userData, token }
          })
          .pipe(tap(getResponseValidator<GuestApplicationRegisterResponse>()))
      )
    );
  }

  public register(userData: UserData, token: string) {
    return this.apollo
      .mutate({
        mutation: registerMutation,
        variables: { userData, token }
      })
      .pipe(tap(getResponseValidator<GuestRegisterResponse>()));
  }

  public delete(reasonData: ReasonData, token: string) {
    return this.apollo
      .mutate({
        mutation: deleteQuery,
        variables: { token, reasonData }
      })
      .pipe(tap(getResponseValidator<GuestMutationResult>()));
  }

  public acceptAppointment(id: string, token: string) {
    return this.apollo
      .mutate({
        mutation: acceptAppointmentMutation,
        variables: { id, token },
        update: () => {
          this.refetchAppointments();
          this.refetchApplication();
        }
      })
      .pipe(tap(getResponseValidator<GuestMutationResult>()));
  }

  public cancelAppointment(
    cancelAppointmentData: CancelAppointmentData,
    token: string
  ) {
    return this.apollo
      .mutate({
        mutation: appointmentAcceptancesCancelMutation,
        variables: { cancelAppointmentData, token },
        update: () => {
          this.refetchAppointments();
          this.refetchApplication();
        }
      })
      .pipe(tap(getResponseValidator<GuestMutationResult>()));
  }

  public declareIntent(declareIntentData: DeclareIntentData, token: string) {
    return this.apollo
      .mutate({
        mutation: declareIntentMutation,
        variables: { declareIntentData, token },
        update: () => this.refetchApplication()
      })
      .pipe(tap(getResponseValidator<GuestMutationResult>()));
  }

  public loadGuestQuestions(token: string) {
    return this.apollo
      .query<GuestQuestionsQueryResponse>({
        query: guestQuestionsQuery,
        variables: { token }
      })
      .pipe(map(response => response.data.guestQuestions));
  }

  private refetchAppointments(): void {
    if (this.appointmentsQuery) {
      void this.appointmentsQuery.refetch();
    }
  }

  private refetchApplication(): void {
    if (this.applicationQuery) {
      void this.applicationQuery.refetch();
    }
  }

  private uploadUserFiles(userProfileData, token: string): Observable<any> {
    const { portrait, creditScore, wbsDocument, incomeProof, otherDocuments } =
      userProfileData || {};
    return zip(
      this.uploadFile(portrait, PropertySearcherDocumentTypes.IMG, token),
      this.uploadFiles(
        creditScore,
        PropertySearcherDocumentTypes.CREDIT_REPORT,
        token
      ),
      this.uploadFiles(
        wbsDocument,
        PropertySearcherDocumentTypes.WB_CERTIFICATE,
        token
      ),
      this.uploadFiles(
        incomeProof,
        PropertySearcherDocumentTypes.INCOME_STATEMENT,
        token
      ),
      this.uploadFiles(
        otherDocuments,
        PropertySearcherDocumentTypes.ADDITIONAL_DOCUMENTS,
        token
      )
    ).pipe(
      map(
        ([
          uploadedPortrait,
          uploadedCreditScore,
          uploadedWbs,
          uploadedIncomeProof,
          uploadedOtherDocuments
        ]) => {
          const documentsToSave = [
            ...uploadedCreditScore,
            ...uploadedWbs,
            ...uploadedIncomeProof,
            ...uploadedOtherDocuments
          ].filter(
            attachment => attachment && !(attachment.file instanceof Blob)
          );
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return {
            ...this.getCleanProfile(userProfileData),
            portrait: uploadedPortrait.length > 0 ? uploadedPortrait[0] : null,
            attachments: [...documentsToSave]
          };
        }
      )
    );
  }

  private getCleanProfile(profileData) {
    const {
      wbsDocument,
      creditScore,
      incomeProof,
      otherDocuments,
      ...restOfProfile
    } = profileData || {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return restOfProfile;
  }

  private uploadFiles(
    attachments: Attachment[] = [],
    type: PropertySearcherDocumentTypes,
    token: string
  ): Observable<Attachment[]> {
    const toUpload = (attachments || []).filter(
      attachment => attachment.file instanceof Blob
    );
    if (!toUpload.length) return of([...(attachments || [])]);

    const files = toUpload.map(attachment => attachment.file) as File[];
    const filesType = PropertySearcherDocumentTypes.SHARED_DOCUMENT;

    return this.fileUploadService.uploadFileWithToken(
      files,
      filesType,
      type,
      token
    );
  }

  private uploadFile(
    attachment: Attachment,
    type: PropertySearcherDocumentTypes,
    token: string
  ): Observable<Attachment[]> {
    const file = (attachment || {}).file;
    if (!(file instanceof File)) return of([attachment]);

    return this.fileUploadService.uploadFileWithToken(
      [file],
      type,
      type,
      token
    );
  }
}
