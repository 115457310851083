import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';

@Component({
  selector: 'app-schufa-success-modal',
  templateUrl: './schufa-success-modal.component.html',
  styleUrls: ['./schufa-success-modal.component.scss'],

  imports: [
    TranslateModule,
    ModalV2Component,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class SchufaSuccessModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    this.ngbActiveModal.close();
  }
}
