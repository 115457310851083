import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  ModalContentComponent,
  ModalV2Component
} from '@ui/legacy-lib';

@Component({
  selector: 'app-payment-positions-request-feedback-modal',
  templateUrl: './payment-positions-request-feedback-modal.component.html',
  styleUrls: ['./payment-positions-request-feedback-modal.component.scss'],

  imports: [
    TranslateModule,
    ModalV2Component,
    ModalContentComponent,
    ButtonComponent
  ]
})
export class PaymentPositionsRequestFeedbackModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public onClose(alreadyPaid: boolean) {
    this.ngbActiveModal.close(alreadyPaid);
  }
}
