import { Component, inject, input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { AsyncPipe, NgStyle } from '@angular/common';
import { RouterLink } from '@angular/router';
import {
  ButtonComponent,
  EmbeddedLinkComponent,
  Go,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';
import { MainPageNavigation } from '../../config';

@Component({
  selector: 'app-confirm-apply-modal',
  templateUrl: './confirm-apply-modal.component.html',
  styleUrls: ['./confirm-apply-modal.component.scss'],

  imports: [
    RouterLink,
    NgStyle,
    TranslateModule,
    AsyncPipe,
    ModalV2Component,
    ModalContentComponent,
    ButtonComponent,
    EmbeddedLinkComponent,
    ModalFooterComponent
  ]
})
export class ConfirmApplyModalComponent {
  readonly profileComplete = input<boolean>(undefined);
  readonly showSchufaLink = input<boolean>(undefined);
  readonly profileCompleteness$ = input<Observable<number>>(of(undefined));
  readonly showFooter = input(true);
  readonly showProfileCompleteness = input(true);
  private ngbActiveModal = inject(NgbActiveModal);
  private store = inject(Store);

  public editProfile() {
    this.store.dispatch(
      new Go({
        path: [MainPageNavigation.PROFILE, 'edit', 'step']
      })
    );
  }

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    this.ngbActiveModal.close();
  }

  public onEditProfile() {
    this.cancel();
    this.editProfile();
  }
}
