import { Component, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  InfoBoxComponent,
  LogoComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';

@Component({
  selector: 'app-new-user-modal',
  templateUrl: './new-user-modal.component.html',
  styleUrls: ['./new-user-modal.component.scss'],

  imports: [
    TranslateModule,
    ModalV2Component,
    ModalContentComponent,
    LogoComponent,
    ModalFooterComponent,
    InfoBoxComponent,
    ButtonComponent
  ]
})
export class NewUserModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
