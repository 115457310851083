import { Component, forwardRef, inject, input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment';

import { NameValue, RentDepositLandlordOption } from '@ui/shared/models';

import {
  AddressFormComponent,
  AppInputDirective,
  DateComponent,
  FlatSelectComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  GERMAN_COUNTRY_CODE,
  HintComponent,
  WizardFooterComponent
} from '@ui/legacy-lib';
import { TranslateModule } from '@ngx-translate/core';
import { RentDepositBaseStep } from '../rent-deposit-base-step.directive';

@UntilDestroy()
@Component({
  selector: 'app-rent-deposit-object',
  templateUrl: './rent-deposit-object.component.html',
  styleUrls: ['./rent-deposit-object.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RentDepositObjectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RentDepositObjectComponent),
      multi: true
    }
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    HintComponent,
    AddressFormComponent,
    FormFieldComponent,
    FlatSelectComponent,
    AppInputDirective,
    DateComponent,
    WizardFooterComponent,
    FormFieldLabelComponent
  ]
})
export class RentDepositObjectComponent
  extends RentDepositBaseStep
  implements OnInit, ControlValueAccessor
{
  readonly genderTypes = input<NameValue<string>>(undefined);
  readonly landlordOptions = input<NameValue<string>>(undefined);
  public formGroup: FormGroup;
  public startDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  public maxDate: NgbDateStruct = {
    year: moment().year() + 10,
    month: moment().month() + 1,
    day: moment().date()
  };
  public minDate: NgbDateStruct = {
    year: moment().year() - 10,
    month: moment().month() + 1,
    day: moment().date()
  };
  public maxDateContractSign: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  private fb = inject(FormBuilder);

  public get addressFormConfig() {
    return { countryName: false };
  }

  public get addressForm() {
    return this.formGroup.get('propertyData').get('address');
  }

  public get landlordForm() {
    return this.formGroup.get('landlordData');
  }

  public get landlordAddressForm() {
    return this.landlordForm.get('landlordAddress');
  }

  public get selectedLandlordRoleForm() {
    return this.landlordForm.get('selectedRole');
  }

  public get isLegalPerson() {
    return (
      this.selectedLandlordRoleForm?.value ===
      RentDepositLandlordOption.LEGAL_PERSON
    );
  }

  public get isNaturalPerson() {
    return (
      this.selectedLandlordRoleForm?.value ===
      RentDepositLandlordOption.NATURAL_PERSON
    );
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      propertyData: this.fb.group({
        address: this.fb.group({
          city: [null, Validators.maxLength(25)],
          zipCode: [
            null,
            {
              validators: Validators.compose([
                Validators.minLength(5),
                Validators.maxLength(5)
              ]),
              updateOn: 'blur'
            }
          ],
          street: [null, Validators.maxLength(30)],
          houseNumber: [null, Validators.maxLength(10)],
          country: [GERMAN_COUNTRY_CODE],
          district: [null]
        })
      }),
      landlordData: this.fb.group({
        selectedRole: RentDepositLandlordOption.LEGAL_PERSON,
        gender: [],
        firstname: [null, Validators.maxLength(30)],
        lastname: [null, Validators.maxLength(30)],
        name: [null, Validators.maxLength(30)], // only for legal person
        landlordAddress: this.fb.group({
          city: [
            null,
            Validators.compose([Validators.required, Validators.maxLength(25)])
          ],
          zipCode: [
            null,
            {
              validators: Validators.compose([
                Validators.minLength(5),
                Validators.maxLength(5),
                Validators.required
              ]),
              updateOn: 'blur'
            }
          ],
          street: [
            null,
            Validators.compose([Validators.required, Validators.maxLength(30)])
          ],
          houseNumber: [
            null,
            Validators.compose([Validators.required, Validators.maxLength(10)])
          ],
          country: [GERMAN_COUNTRY_CODE, Validators.required],
          district: [null]
        })
      }),
      contractData: this.fb.group({
        startOfRentalContract: [new Date()],
        isRentalContractUnlimited: [],
        rentalContractSignDate: [],
        paymentType: [null],
        paymentAmount: [],
        totalDeposit: []
      })
    });

    this.selectedLandlordRoleForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(selectedRole => {
        if (selectedRole === RentDepositLandlordOption.NATURAL_PERSON) {
          this.landlordForm.get('name').disable();
          this.landlordForm.get('gender').enable();
          this.landlordForm.get('firstname').enable();
          this.landlordForm.get('lastname').enable();
        } else {
          this.landlordForm.get('name').enable();
          this.landlordForm.get('gender').disable();
          this.landlordForm.get('firstname').disable();
          this.landlordForm.get('lastname').disable();
        }
      });

    this.formGroup.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
      this.onTouch();
    });
  }

  public nextStep() {
    super.nextStep();
  }

  public validate() {
    return this.formGroup.valid ? null : { missingFields: true };
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }

  private onChange: (args) => any = () => null;

  private onTouch: () => any = () => null;
}
