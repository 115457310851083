import { Component, inject, input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  ModalContentComponent,
  ModalFooterComponent,
  ModalV2Component
} from '@ui/legacy-lib';

@Component({
  selector: 'app-apply-confirmation',
  templateUrl: './apply-confirmation.component.html',
  styleUrls: ['./apply-confirmation.component.scss'],

  imports: [
    TranslateModule,
    ModalV2Component,
    ModalContentComponent,
    ModalFooterComponent,
    ButtonComponent
  ]
})
export class ApplyConfirmationComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  readonly name = input<string>(undefined);

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
