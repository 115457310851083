import { Component, inject, input, output } from '@angular/core';
import {
  PropertyMatchBean,
  RentDepositModel,
  RentDepositPaymentOption
} from '@ui/shared/models';

import { take } from 'rxjs/operators';
import {
  AddressPipe,
  ButtonComponent,
  DateTimePipe,
  DownloadService,
  FullNamePipe,
  SlideToggleComponent,
  WizardFooterComponent
} from '@ui/legacy-lib';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CurrencyPipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RentDepositSummaryCardHeaderComponent } from '../rent-deposit-summary-card-header/rent-deposit-summary-card-header.component';
import { ProtocolPdfService } from '../../../screens/rent-deposit/services/protocol-pdf.service';
import { RentDepositBaseStep } from '../rent-deposit-base-step.directive';

@Component({
  selector: 'app-rent-deposit-summary',
  templateUrl: './rent-deposit-summary.component.html',
  styleUrls: ['./rent-deposit-summary.component.scss'],
  providers: [ProtocolPdfService],

  imports: [
    RentDepositSummaryCardHeaderComponent,

    FormsModule,
    TranslateModule,
    CurrencyPipe,
    AddressPipe,
    DateTimePipe,
    FullNamePipe,
    SlideToggleComponent,
    ButtonComponent,
    WizardFooterComponent
  ]
})
export class RentDepositSummaryComponent extends RentDepositBaseStep {
  readonly rentDepositModel = input<RentDepositModel>(undefined);
  readonly propertyMatch = input<PropertyMatchBean>(undefined);
  readonly processing = input<boolean>(undefined);
  readonly edit = output<string>();
  readonly privacyModal = output();
  public acknowledgePrivacy: boolean;
  private protocolService = inject(ProtocolPdfService);
  private downloadService = inject(DownloadService);
  private translateService = inject(TranslateService);

  public get rentDepositInfo() {
    return this.propertyMatch()?.rentDepositInfo;
  }

  public get isNaturalPerson() {
    return this.rentDepositModel()?.landlordData.realPerson;
  }

  public get isYearly() {
    return (
      this.rentDepositModel()?.contractData?.paymentType ===
      RentDepositPaymentOption.YEARLY
    );
  }

  public get isMonthly() {
    return (
      this.rentDepositModel()?.contractData?.paymentType ===
      RentDepositPaymentOption.MONTHLY
    );
  }

  public openPrivacyModal() {
    this.privacyModal.emit();
  }

  public onGoToStep(stepName: string) {
    this.edit.emit(stepName);
  }

  public onCompleteStep() {
    super.completeStep();
  }

  public getProtocolPdf() {
    this.protocolService
      .getProtocolFile(this.rentDepositModel())
      .pipe(take(1))
      .subscribe((file: Blob) => {
        const rentDepositModel = this.rentDepositModel();
        this.downloadService.download(
          file,
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `${this.translateService.instant('rent-deposit.protocol-pdf-name_l', {
            firstName: rentDepositModel?.tenantData?.firstname,
            lastName: rentDepositModel?.tenantData?.lastname
          })}.pdf`
        );
      });
  }
}
