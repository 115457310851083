import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';
import { BadgeComponent } from '@ui/legacy-lib';
import { TenantTooltipDirective } from '../tenant-tooltip/tenant-tooltip.directive';
import { StepTenant } from '../../models/step.tenant';

@Component({
  selector: 'app-profile-dk-explanation',
  templateUrl: './profile-dk-explanation.component.html',
  styleUrls: ['./profile-dk-explanation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  imports: [TenantTooltipDirective, TranslateModule, BadgeComponent]
})
export class ProfileDkExplanationComponent {
  readonly currentStep = input<StepTenant>(undefined);
  readonly stepIndex = input<number>(undefined);

  public get label() {
    return `general.dk_phase_${this.currentStep().name}_l`;
  }

  public get description() {
    return `general.dk_phase_${this.currentStep().name}_description_l`;
  }
}
