<div class="profile__content">
  <p class="content__sub-title uppercase-m semibold">
    {{ 'general.step' | translate }} {{ stepIndex() + 1 }}
  </p>
  <h1 class="content__title title-xl">
    {{ steps()[stepIndex()].nameNice | translate }}
  </h1>

  <app-profile-summary
    [form]="form()"
    [userData]="userData()"
    [guestMode]="guestMode()"
    [searchProfiles]="searchProfiles()"
    [schufaCard]="schufaCard()"
    (editSearchProfile)="onEditSearchProfile($event)"
    (deleteSearchProfile)="onDeleteSearchProfile($event)"
    (addSearchProfile)="onAddSearchProfile()"
  >
  </app-profile-summary>
</div>
