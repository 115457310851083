// This file contains the shared default values for all environments
// The individual values can be specifically overwritten for each environment

export const defaultEnvironment = {
  app_name: 'tenant',
  app_version: '1.25.0',
  android_store: 'market://details?id=com.immomio.tenant',
  apple_store: 'itms-apps://itunes.apple.com/app/id1534838246',
  auth_header_name: 'authorization',
  file_download_auth_name: 'X-Auth-Token',
  login_method: 'DEFAULT',

  freshchat_token: '14147a7b-9cd7-4109-ae90-af5ce1366f60',

  google_api_key: 'AIzaSyAhx_9WONL-d74ojku6o0qEcBw87hV69lE',
  survicate_workspace_key: '8b14a7f4c0f657d7b9747c9705b00084',

  keycloakConfig: {
    'ssl-required': 'none',
    resource: 'immomio-oidc',
    'public-client': true,
    'verify-token-audience': true,
    'use-resource-role-mappings': true,
    'confidential-port': 0
  }
};
